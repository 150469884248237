import React, { useState, useEffect, useRef } from "react";
import { deleteSubscription } from "../../services/api/api.js";
import QRCode from "qrcode.react";
import CopyToClipboard from "react-copy-to-clipboard";
import "./modal.css";
import LutasPerfil from "./LutasPerfil.js";
import AlterarInscricao from "./AlterarInscricao.js";
import "./AlterarInscricoes.css";

const SubscriptionLookup = ({ userCPF, professor }) => {
  const [showFighterSelection, setShowFighterSelection] = useState(false);
  const [subscriptions, setSubscriptions] = useState([]);
  const [showModal, setShowModal] = useState(null);
  const [showPaymentModal, setShowPaymentModal] = useState(null);
  const [aviso, setAviso] = useState("Carregando inscrições...");
  const [copied, setCopied] = useState(false);
  const modalRef = useRef(null);
  const [toggleAlterarInscricao, setToggleAlterarInscricao] = useState(false);
  const [flagIniciou, setFlagIniciou] = useState(false);

  //substituir condição do modal para algo desacoplado do objeto

  const handleDelete = async (id, index) => {
    const shouldDelete = window.confirm("Tem certeza que deseja excluir esta inscrição?");

    if (shouldDelete) {
      const deleted = await deleteSubscription(id);

      if (deleted) {
        alert("Inscrição excluída com sucesso!");
        const novaLista = [...subscriptions];
        novaLista.splice(index, 1);
        setSubscriptions(novaLista);
      } else {
        console.error("Erro ao excluir inscrição");
      }
    }
  };

  const handleAlterarInscricao = (subscription) => {
    if (subscription.inscricaoOponente === null) {
      setToggleAlterarInscricao(!toggleAlterarInscricao);
    } else {
      alert(
        "Já há um oponente cadastrado para te desafiar, entre em contato para que possamos te ajudar a alterar a sua inscrição, por favor."
      );
    }
  };

  const handlePayment = (subscription) => {
    setShowModal(subscription);

    //Esse if deveria considerar o que iniciou luta sem oponente e ir direto para pagamento. Mas eu mudei, então todos vão ver a lista no repay

    if (subscription.escolheuIniciar === "sim") {
      setShowFighterSelection(true);
      setFlagIniciou(true);
      
    } else if (
      subscription.tipo === "casada" &&
      subscription.oponente === null
    ) {
      setShowFighterSelection(true);
    } else {
      setShowPaymentModal(true);
    }
  };

  const onIniciarSelection = () => {
    setShowFighterSelection(false);
    setShowPaymentModal(true);
  };

  const onFightSelection = () => {
    setShowFighterSelection(false);
    setShowPaymentModal(true);
  };

  const closeBtn = () => {
    setShowModal(null);
    setShowFighterSelection(false);
    setShowPaymentModal(false);
  };

  const closeSidebar = (event) => {
    if (modalRef.current && modalRef.current.contains(event.target)) {
      // Click occurred inside the modal content, do nothing
      return;
    }

    setShowModal(null);
    setShowFighterSelection(false);
    setShowPaymentModal(false);
  };

  useEffect(() => {
    document.addEventListener("mousedown", closeSidebar);

    return () => {
      document.removeEventListener("mousedown", closeSidebar);
    };
  }, []);

  const generatePaymentReceipt = (subscription) => {};

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://jacquesgomes.com.br/FightEventsBackend/cpf/" + userCPF
        );

        if (response.status === 200) {
          const data = await response.json();
          setSubscriptions(data);
        } else {
          setSubscriptions([]);
          setAviso("Nenhuma inscrição encontrada.");
        }
      } catch (error) {
        console.error("Erro:", error);
        alert("Um erro ocorreu ao buscar os dados");
      }
    };

    fetchData();
  }, [userCPF]);

  return (
    <div className="consulta-perfil sub-info-div">
      <h3 className="spaced-title">Inscrições recentes</h3>
      {subscriptions.length > 0 ? (
        <span className="dashboard-content">
          {subscriptions.map((subscription, index) => (
            <span className="subscription-card" key={index}>
              <h4>{subscription.tituloEvento}</h4>
              <p>
                <strong>Local:</strong> {subscription.localDoEvento}
              </p>
              <p>
                <strong>Data:</strong> {subscription.dataDoEvento}
              </p>
              <p>
                <strong>Categoria:</strong> {subscription.faixa},{" "}
                {subscription.sexo}, {subscription.peso}, {subscription.idade},
                Absoluto: {subscription.absoluto}
              </p>
              <p>
                <strong>Número da Inscrição:</strong> {subscription.id}
              </p>
              <p>
                <strong>Ingresso:</strong> {subscription.valorIngresso}
              </p>
              <p>
                <strong>Situação do Pagamento:</strong>{" "}
                {subscription.situacaoPagamento}
              </p>
              <p>
                <strong>Situação da Inscrição:</strong> {subscription.status}
              </p>

              <span className="button-container">
                {subscription.situacaoPagamento === "Pendente" && (
                  <button onClick={() => handlePayment(subscription)}>
                    Pagar
                  </button>
                )}

                {subscription.situacaoPagamento === "Pendente" && (
                  <button onClick={() => handleDelete(subscription.id)}>
                    Excluir inscrição
                  </button>
                )}

                {
                  <button onClick={() => handleAlterarInscricao(subscription)}>
                    {toggleAlterarInscricao ? (
                      <>Cancelar alterações</>
                    ) : (
                      <>Alterar inscrição</>
                    )}
                  </button>
                }

                {showFighterSelection && (
                  <>
                    <div className="modal-perfil" onClick={closeSidebar}>
                      <div
                        id="close"
                        className="modal-content-perfil"
                        ref={modalRef}
                      >
                        <span className="close" onClick={closeBtn}>
                          &times;
                        </span>

                        <LutasPerfil
                        flagIniciou={flagIniciou}
                          data={showModal}
                          onFightSelection={onFightSelection}
                          onIniciarSelection={onIniciarSelection}
                        />
                        {/* component with data */}
                      </div>
                    </div>
                  </>
                )}

                {showPaymentModal && (
                  <>
                    <div className="modal-perfil" onClick={closeSidebar}>
                      <div
                        id="close"
                        className="modal-content-perfil"
                        ref={modalRef}
                      >
                        <span className="close" onClick={closeBtn}>
                          &times;
                        </span>

                        {showModal.qr_code !== "Pendente" ? (
                          <>
                            <div className="qrcode-container">
                              <QRCode value={showModal.qr_code} size={128} />
                            </div>

                            <div className="copy-code-container">
                              <p>Código Pix:</p>
                              <input
                                type="text"
                                value={showModal.qr_code}
                                readOnly
                              />
                              {copied && (
                                <p
                                  style={{
                                    marginTop: "5px",
                                    textAlign: "center",
                                  }}
                                >
                                  Copiado!
                                </p>
                              )}
                            </div>

                            <CopyToClipboard
                              text={showModal.qr_code}
                              onCopy={() => setCopied(true)}
                            >
                              <button>Copiar código</button>
                            </CopyToClipboard>
                          </>
                        ) : (
                          <p style={{color: 'red'}}>
                            Algum erro aconteceu ao gerar seu código de
                            pagamento, por favor, entre em contato conosco para
                            que possamos resolver.
                          </p>
                        )}
                      </div>
                    </div>
                  </>
                )}

                {subscription.situacaoPagamento === "Pago" && (
                  <button
                    onClick={() => generatePaymentReceipt(subscription.id)}
                  >
                    Emitir Comprovante
                  </button>
                )}

                {subscription.tipo === "casada" ? null : (
                  <button
                    className="mais-info-btn"
                    onClick={() => handleDelete(subscription.id, index)}
                  >
                    Cancelar inscrição
                  </button>
                )}
              </span>

              {toggleAlterarInscricao && (
                <AlterarInscricao
                  professor={professor}
                  subscription={subscription}
                />
              )}

              <span className="line" />
            </span>
          ))}
        </span>
      ) : (
        aviso
      )}
    </div>
  );
};

export default SubscriptionLookup;
