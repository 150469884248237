export async function getFighterByCPF(cpf) {

    const response = await fetch(`https://jacquesgomes.com.br/FightEventsBackend/checarCPF/${cpf}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      }
    });
  
    if (response.status === 200) {
 
      return true;
    } else {
      return false;
    }
  }