import React, { useState, useEffect } from "react";
// import { formatarHorarios } from "../../utils/formatarHorarios";

const LutaCasada = ({ data, onFightSelection, onIniciarSelection, flagIniciou }) => {
  const [selectedFight, setSelectedFight] = useState("");
  const [availableFights, setAvailableFights] = useState([]);
  const [loading, setLoading] = useState(true);

  // const [selectedTime, setSelectedTime] = useState("");

  // const handleTimeSelection = (event) => {
  //   setSelectedTime(event.target.value);
  // };

  // const availableTimes = formatarHorarios(data.horarios);

  useEffect(() => {
    const realFormData = {
      eventId: data.eventId,
      faixa: data.faixa,
      sexo: data.sexo,
      peso: data.peso,
      fighterId: data.fighterId,
      ingresso: data.valorIngresso,
    };

    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://jacquesgomes.com.br/FightEventsBackend/inscricoes-lutas",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(realFormData),
          }
        );

        if (response.ok) {
          const data = await response.json();
          // Define o estado com os resultados filtrados
          setAvailableFights(data);
          setLoading(false);
        } else {
          console.error("Error sending form data");
          setLoading(false);
        }
      } catch (error) {
        console.error("Error sending form data:", error);
        setLoading(false);
      }
    };

    fetchData();
    // onClose();
  }, []);

  const onUpdateFights = (luta, lutador) => {
    createSubmission(luta, lutador);
  };

  const createSubmission = async (inscricaoOponente, oponenteId) => {
    const updateFightsData = {
      fighterId: data.fighterId,
      inscricaoId: data.id,
      inscricaoOponente,
      oponenteId,
    };

    try {
      const response = await fetch(
        "https://jacquesgomes.com.br/FightEventsBackend/inscricoes-lutas/update",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(updateFightsData),
        }
      );

      if (response.ok) {
        onFightSelection();
      } else {
        alert("Erro ao desafiar oponente, por favor entre em contato conosco.");
        console.error("Error sending form data");
      }
    } catch (error) {
      console.error("Error sending form data:", error);
      alert("Erro ao desafiar oponente, por favor entre em contato conosco.");
    }
  };

  return (
    <div>
      {loading ? (
        <></>
      ) : (
        <>
          {/* Esse aviso abaixo deve ser condicional */}
          {!flagIniciou && <p className="aviso-pagamento">
            <strong>Aviso: </strong>sua inscrição passou mais de 5 horas sem
            pagamento, por esse motivo é necessário que você selecione novamente
            a opção de luta casada desejada.{" "}
          </p>}

          {availableFights.length > 0 && (
            <div className="available-fights">
              <h4>Lutas disponíveis:</h4>
              <div className="fights-container">
                {availableFights.map((fight) => (
                  <div key={fight.id} className="fight-card">
                    <div>
                      <strong>Atleta: {fight.nome}</strong>
                      {fight.horarioLuta ? (
                        <p>Horário: {fight.horarioLuta}</p>
                      ) : (
                        <></>
                      )}
                      {}
                      <p>Peso: {fight.peso}</p>
                      <p>Idade: {fight.idade}</p>
                      <p>Graduação: {fight.faixa}</p>
                      <p>Academia: {fight.academia}</p>
                      {fight.instagram ? (
                        <p>Instagram: {fight.instagram}</p>
                      ) : (
                        <></>
                      )}
                    </div>

                    <button
                      onClick={() => onUpdateFights(fight.id, fight.fighterId)}
                    >
                      Desafiar
                    </button>
                  </div>
                ))}
              </div>

              <div className="horarios">
                <p>
                  Caso não queira desafiar nenhum dos atletas listados, você
                  pode iniciar uma nova luta e esperar que alguém te desafie.
                </p>

                <p>
                  <strong>Importante: </strong> seu nome só será listado nas
                  lutas disponíveis após a confirmação do pagamento!{" "}
                </p>

                <button onClick={onIniciarSelection}>Iniciar nova luta</button>
              </div>
            </div>
          )}

          {availableFights.length < 1 && (
            <div className="horarios">
              <p>
                Não encontramos nenhum oponente na sua categoria. Inicie uma
                nova luta e espere até que algum oponente te desafie.
              </p>
              <p>Nós te avisaremos por email assim que alguém te desafiar.</p>
              <button onClick={onIniciarSelection}>Iniciar nova luta</button>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default LutaCasada;
