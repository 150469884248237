// Edital.jsx
import React from 'react';

const Edital = ({ evento, handleVoltar }) => {

  return (
    <div>
        {/* <button onClick={handleVoltar}><i className="uil uil-multiply"></i></button> */}
        
      <h2>{evento.titulo}</h2>
      <p><strong>Tipo:</strong> {evento.tipo}</p>
      <p><strong>Modalidade:</strong> {evento.modalidade}</p>
      <p><strong>Data do Evento:</strong> {evento.dataOculta}</p>
      <p><strong>Data de Inscrições:</strong> {evento.dataInscricoes}</p>
      <p><strong>Data de Pagamento:</strong> {evento.dataPagamento}</p>
      <p><strong>Data das Chaves:</strong> {evento.dataChaves}</p>
      <p><strong>Data de Checagem:</strong> {evento.dataChecagem}</p>
      <p><strong>Local:</strong> {evento.local}</p>
      <p><strong>Espaço:</strong> {evento.espaco}</p>
      <p><strong>Endereço:</strong> {evento.endereco}</p>
      <p><strong>Link do Mapa:</strong> <a href={evento.linkMapas} target="_blank" rel="noopener noreferrer">Ver no Mapa</a></p>
      <p><strong>Valores de Inscrições:</strong> {evento.valoresInscricoes}</p>
      <p><strong>Sobre o Evento:</strong> {evento.sobreEvento}</p>
      <p><strong>Sobre a Organização:</strong> {evento.sobreOrganizacao}</p>
      <p><strong>Premiação:</strong> {evento.premiacao}</p>
      <p><strong>Horários:</strong> {evento.horarios}</p>

      {/* <button className='chaveamento-btn' style={{marginRight: '20px', marginTop: '20px'}}>
      <a href={process.env.PUBLIC_URL + evento.id + '/.pdf'} download="Chaveamento.pdf">Gerar PDF</a>
      </button> */}

      <button style={{marginTop: '20px'}} onClick={handleVoltar}>Voltar</button>


    </div>
  );
};

export default Edital;
