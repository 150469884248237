import React from 'react';

const TermosCondicoesServico = ({ onClose }) => {
  return (
    <div className="termos-container">
      <span onClick={onClose}>&times;</span>
      <h3>Termos de Acordo e Aceite</h3>
      <p>
        Eu, “identificado no cadastramento da inscrição”, no perfeito uso de minhas faculdades, DECLARO para os devidos fins de direito que:
      </p>
      <ol>
        <li>Estou ciente de que se trata de uma competição de Jiu-Jitsu de nível regional.</li>
        <li>Estou em plenas condições físicas e psicológicas de participar desta COMPETIÇÃO e estou ciente que não existe nenhuma recomendação médica que me impeça de praticar atividades físicas.</li>
        <li>Assumo, por minha livre e espontânea vontade, todos os riscos envolvidos e suas consequências pela participação neste EVENTO, que incluem possibilidade de invalidez e morte, isentando a organização, seus organizadores, colaboradores e patrocinadores DE TODA E QUALQUER RESPONSABILIDADE por quaisquer danos materiais, morais ou físicos, que porventura venha a sofrer, advindos da participação neste EVENTO.</li>
        <li>Li, conheço, aceito e me submeto integralmente a todos os termos do regulamento da COMPETIÇÃO.</li>
        <li>Declaro que não portarei, nem utilizarei, nas áreas do evento, ou outra área de visibilidade no evento, como meios de divulgação e promoção, nenhum material publicitário, promocional ou político, sem a devida autorização por escrito dos organizadores; e também, qualquer material ou objeto que ponha em risco a segurança do evento, dos participantes e ou das pessoas presentes, aceitando ser retirado pela organização ou autoridades, das áreas acima descritas.</li>
        <li>Em caso de participação neste evento, representando equipes de participantes ou prestadores de serviços e ou qualquer mídia ou veículo, declaro ter pleno conhecimento, e que aceito o regulamento do evento, bem como, a respeitar as áreas da organização destinadas às mesmas, e que está vedada minha participação nas estruturas de apoio a equipes montadas em locais inadequados, ou que interfiram no andamento do evento, e também locais sem autorização por escrito da organização, podendo ser retirado da competição e do local do evento em qualquer tempo.</li>
        <li>Estou ciente das penalidades e possível desclassificação que posso sofrer caso descumpra o regulamento ou cometa algum tipo de falta. Excluo meu direito de reclamação sobre tais aspectos da competição.</li>
        <li>Autorizo o uso de minha imagem, assim como familiares e amigos, para fins de divulgação do evento, por fotos, vídeos e entrevistas em qualquer meio de comunicação, sem geração de ônus para a organização, mídia e patrocinadores.</li>
        <li>Estou ciente que na hipótese de suspensão do evento por questões de segurança pública, caso fortuito ou força maior, todos os eventuais custos referentes à locomoção, preparação, estadia, inscrição, entre outros gastos despendidos pelo atleta será suportado única e exclusivamente por mim, isentando a Comissão Organizadora e a empresa responsável pelo ressarcimento de qualquer destes custos.</li>
      </ol>

      <span onClick={onClose}>&times;</span>
    </div>
  );
};

export default TermosCondicoesServico;
