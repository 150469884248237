import React from 'react';
import eventImg from '../../assets/imgs/arena3.webp'

export function EventCard({ event }) {

  return (
    <div className="event-card">
  
        <div className='event-image'>

        <img src={eventImg} alt='event-img'></img>

        </div>
        <div className='event-text'>
      <h3>{event.titulo}</h3>
      <p>Data: {event.data}</p>
      <p>Inscrições até: {event.dataInscricoes}</p>
      <p>Local: {event.local}</p>
      </div>
    </div>
  );
}
