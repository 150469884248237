import React from "react";
import { Link } from "react-router-dom";
import "./footer.css";
import WhatsApp from "../../assets/imgs/whatsBlack.svg";
import Facebook from "../../assets/imgs/facebook.svg";
import Instagram from "../../assets/imgs/instagram.svg";

const scrollToTop = () => {
  window.scrollTo(0, 0);
};

const Footer = () => {
  const main = "/";

  return (
    <div className="footer__container">
      <div className="footer">
        <div className="footer__child">
          <div>
            <h3>SOBRE</h3>
            <p>
              Somos uma empresa dedicada à produção e promoção de eventos de
              jiu-jitsu. Nosso objetivo é proporcionar experiências incríveis
              para a comunidade do jiu-jitsu e promover o esporte em todo o
              país.
            </p>
          </div>

          <div className="footer-icons">
            <h3>REDES</h3>
            <div className="footer-icons-content">
              <a href="https://api.whatsapp.com/send/?phone=5584994514529&text&type=phone_number&app_absent=0"  rel="noreferrer" target="_blank">
                <img src={WhatsApp} alt="WhatsApp" />
              </a>

              {/* <a href="your_facebook_link" target="_blank">
                <img src={Facebook} alt="Facebook" />
              </a> */}

              <a href="https://www.instagram.com/campeonato_caveiraofc/" rel="noreferrer" target="_blank">
                <img src={Instagram} alt="Instagram" />
              </a>
            </div>
          </div>
        </div>

        <div className="footer__child">
          <h3>SUPORTE</h3>

          <Link to={`${main}contato/`} onClick={scrollToTop}>
            Contato
          </Link>
        </div>

        <div className="footer__child footer__menu">
          <h3>MENU</h3>

          <Link to={main} onClick={scrollToTop}>
            Início
          </Link>
          <Link to={`${main}checagem/`} onClick={scrollToTop}>
            Checagem
          </Link>
          <Link to={`${main}chaveamento/`} onClick={scrollToTop}>
            Chaveamento
          </Link>
          <Link to={`${main}contato/`} onClick={scrollToTop}>
            Contato
          </Link>

          <Link to={`${main}privacidade/`}>Política de Privacidade</Link>
          <Link to={`${main}cookies/`}>Política de Cookies</Link>
          <Link to={`${main}termos-de-uso/`}>Termos de Uso</Link>
        </div>
      </div>

      <div className="copy__container">
        <div className="copy">
          {" "}
          <p>
            Desenvolvido por{" "}
            <a href="https://jacquesgomes.com.br/portfolio/">Jacques Gomes</a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
