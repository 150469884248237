import React from "react";
import "./carregando.css";

export function Carregando() {
  return (
    <div className="loading-modal-overlay">
      <div className="loading-modal-content">
        <div className="loading-spinner" />
        <p>Carregando...</p>
      </div>
    </div>
  );
}

export default Carregando;
