import React, { useState, useEffect } from 'react';
import Formulario from './CadastrarAtleta';
import AlterarAtleta from './AlterarAtleta';
import InscricoesAtleta from '../perfil/InscricoesPerfil';
import "./meus-atletas.css";

function ListaDeAtletas(props) {
  const [dadosDosAtletas, setDadosDosAtletas] = useState([]);
  const [carregando, setCarregando] = useState(true);
  const [mostrarFormulario, setMostrarFormulario] = useState(false);
  const [toggleStates, setToggleStates] = useState([]);

  const mudarDadosAtleta = (data, index) => {
    setDadosDosAtletas((prevDadosDosAtletas) => {
      const novosDadosDosAtletas = [...prevDadosDosAtletas];
      novosDadosDosAtletas[index] = data;
      return novosDadosDosAtletas;
    });

    setToggleStates((prevState) =>
    prevState.map((prevStateItem) => ({
      ...prevStateItem,
      alterarDados: false,
    }))
  );
  };

  const adicionarNovoAtleta = (novoAtleta) => {
    setDadosDosAtletas((prevDadosDosAtletas) => {
      const novosDadosDosAtletas = [...prevDadosDosAtletas];
      novosDadosDosAtletas.push(novoAtleta);
      return novosDadosDosAtletas;
    });

    setToggleStates((prevToggleStates) => {
      const novosToggleStates = [...prevToggleStates];
      novosToggleStates.push({
        maisInfo: false,
        maisInscricoes: false,
        alterarDados: false,
      });
      return novosToggleStates;
    });
  }



  useEffect(() => {
    const userId = localStorage.getItem('user');

    const buscarDadosDosAtletas = () => {
      fetch(`https://jacquesgomes.com.br/FightEventsBackend/meus-atletas/` + userId)
        .then((response) => {
          if (!response.ok) {
            throw new Error('Falha ao buscar os dados dos atletas');
          }
          return response.json();
        })
        .then((data) => {
          setDadosDosAtletas(data);
          const initialToggleStates = data.map(() => ({
            maisInfo: false,
            maisInscricoes: false,
            alterarDados: false,
          }));
          setToggleStates(initialToggleStates);
          setCarregando(false);
        })
        .catch((error) => {
          console.error('Erro ao buscar os dados dos atletas:', error);
          setCarregando(false);
        });
    };

    buscarDadosDosAtletas();
  }, []);

  const handleMostrarFormulario = () => {
    setMostrarFormulario(true);
  };

  const handleCadastroConcluido = () => {
    setMostrarFormulario(false);
  };

  const toggleAtletaInfo = (index, type) => {
    setToggleStates((prevState) => {
      const newState = prevState.map((prevStateItem, i) => {
        if (i === index) {
          return {
            ...prevStateItem,
            [type]: !prevStateItem[type],
          };
        }
        return prevStateItem;
      });
      return newState;
    });
  };

  if (carregando) {
    return <p>Carregando os dados dos atletas...</p>;
  }

  return (
    <div className='meus-atletas'>
      {mostrarFormulario ? (
        <div>
          <h3>CADASTRAR ATLETA</h3>
          <Formulario onCadastroConcluido={handleCadastroConcluido} adicionarNovoAtleta={adicionarNovoAtleta} email={props.email} />

        </div>
      ) : (
        <div className="meus-atletas-container">
          <div className='meus-atletas-header'>
            <h3>MEUS ATLETAS</h3>
          </div>
          <div className='cadastrar-div'>
            <button onClick={handleMostrarFormulario}>Cadastrar atleta</button>
          </div>
          {dadosDosAtletas.length > 0 ? (
            dadosDosAtletas.map((atleta, index) => (
              
              <div className='meus-atletas-child' key={index}>
              <span className='line'></span>
                <p>
                <strong><p className="meus-atletas-title">Atleta: {atleta.nomeCompleto} </p></strong>
                      <p>CPF: {atleta.cpf}</p>
                      <p>Data de Nascimento: {atleta.dataDeNascimento}</p>
                      
                      
                      {/* Peso: {atleta.peso} <br /> */}
                  Graduação: {atleta.graduacao}
                
                      {/* <p>Professor: {atleta.professor}</p> */}
                      {/* <p>Equipe: {atleta.equipe}</p> */}
                      <p>Sexo: {atleta.sexo}</p>
                      <p>Instagram: {atleta.instagram}</p>

                  {toggleStates[index].maisInscricoes && (
                    <InscricoesAtleta userCPF={atleta.cpf} />
                  )}
                  {toggleStates[index].alterarDados && (
                    <AlterarAtleta dadosAtleta={atleta} mudarDadosAtleta={mudarDadosAtleta} index={index} />
                  )}

                <div className='meus-atletas-btns'>
                  <button className='mais-info-btn' onClick={() => toggleAtletaInfo(index, 'maisInscricoes')}>
                    {toggleStates[index].maisInscricoes ? 'Esconder inscrições' : 'Consultar inscrições'}
                  </button>
                  <button className='mais-info-btn' onClick={() => toggleAtletaInfo(index, 'alterarDados')}>
                    {toggleStates[index].alterarDados ? 'Cancelar alterações' : 'Alterar dados'}
                  </button>
                </div>
                </p>
                
              </div>
            ))
          ) : (
            <p>Nenhum atleta encontrado.</p>
          )}
        </div>
      )}
    </div>
  );
}

export default ListaDeAtletas;
