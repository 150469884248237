import React from "react";
import "./contato.css";

export function Contato() {
  return (
    <div className="contato">
      <div>
        <h1 className="contato-gambiarra">Contato</h1>
        <p>Entre em contato para qualquer dúvida, sugestão ou parceria.</p>

        <div className="contato-whats">
          <h4>Whatsapp da administração:</h4> (84) 98844-0724
        </div>


        <div className="contato-whats">
          <h4>Email: </h4>  caveirajiujitsu10@gmail.com
        </div>
        

        <div>
          <h4>Whatsapp do suporte:</h4> (84) 99451-4529
        </div>

      </div>

      <div>
        <h3>Quer divulgar seu evento de jiu-jitsu?</h3>
        <p>
          Entre em contato para discutir oportunidades de parceria e divulgação
          do seu evento.
        </p>
      </div>

      <div>
        <h3>
          Quer patrocinar algum evento, atleta, equipe ou iniciativa social de
          jiu-jitsu?
        </h3>
        <p>
          Entre em contato para discutir oportunidades de parceria. Venha fazer
          parte do time que promove a saúde e o esporte!
        </p>
      </div>
    </div>
  );
}

export default Contato;
