import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './criar-conta.css';
import Cadastro from './CriarConta';
import { getFighterByCPF } from './getFighterByCPF';

function CadastroInicial() {
  const [tipoUsuario, setTipoUsuario] = useState('atleta');
  const [showForm, setShowForm] = useState(false);
  const [error, setError] = useState('');

  const [cpf, setCpf] = useState("");

  const handleTipoUsuarioChange = (e) => {
    setTipoUsuario(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const fetchedFighter = await getFighterByCPF(cpf);

    if (fetchedFighter) {
      setError('CPF duplicado, por favor insira outro.');
    } else {
      setShowForm(true);
    }
  };


  const handleCPFInputChange = (e) => {
    const inputValue = e.target.value.replace(/[^0-9]/g, "");

    if (inputValue.length <= 11) {
      setCpf(inputValue);
    }
  };


  return (
<>

{showForm ? (<Cadastro tipoUsuario={tipoUsuario} cpf={cpf}/>):(
  <div className="login__container">
  <div className="criar-content">
  
  <form className="login-form" onSubmit={handleSubmit}>
  <h3>Cadastro Inicial</h3>
  
    <div className='form-group'>
      <label>Tipo de Usuário:</label>
      <select value={tipoUsuario} onChange={handleTipoUsuarioChange}>
        <option value="atleta">Atleta ou Professor</option>
        <option value="responsavel">Somente responsável por atletas</option>
      </select>
    </div>

    <div className="form-group">
              <label htmlFor="cpf">CPF*</label>
              <input
                required
                type="text"
                placeholder="Somente números, ex: 98712345629"
                id="cpf"
                value={cpf}
                onChange={(e) => handleCPFInputChange(e)}
              />
            </div>

            {error && <p style={{color: 'red', marginTop: '5px', marginBottom: '5px'}}>Esse CPF já foi cadastrado, por favor insira outro ou <Link style={{color: 'red', textDecoration: 'underline'}}> recupere a sua senha</Link>.</p>}


      <button type="submit">Próximo</button>

  </form>
</div>
</div>
)
}


    
    </>

  );
}

export default CadastroInicial;
