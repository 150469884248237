import React from "react";
import './politicas.css';
export function Privacidade() {
  return (
    <div className="politicas">
      <div>
        <h1>Política de Privacidade</h1>

        <h3>Introdução</h3>
        <p>
          Esta Política de Privacidade descreve como o site JiuJitsu Caveira
          coleta, usa e protege os dados pessoais dos nossos visitantes. Ao usar
          este site, você concorda com os termos desta política.
        </p>

        <h3>Informações Pessoais</h3>
        <p>
          Podemos coletar informações pessoais, como nome, endereço de e-mail,
          número de telefone e outras informações de contato quando você se
          inscreve para participar de nossos eventos ou se comunica conosco.
        </p>

        <h3>Dados de Pagamento</h3>
        <p>
          Ao fazer pagamentos em nosso site, podemos coletar informações
          financeiras, como números de cartão de crédito ou outras informações
          de pagamento. No entanto, garantimos a segurança dessas informações
          por meio de medidas apropriadas.
        </p>

        <h3>Uso dos Dados</h3>
        <p>Utilizamos os dados pessoais coletados para:</p>
        <ul>
          <li>Processar inscrições em nossos eventos de jiu-jitsu.</li>
          <li>
            Enviar informações sobre os eventos e atualizações relevantes.
          </li>
          <li>Responder a consultas e fornecer suporte ao cliente.</li>
        </ul>

        <h3>Compartilhamento de Dados</h3>
        <p>
          Não compartilhamos suas informações pessoais com terceiros, exceto
          quando necessário para cumprir nossas obrigações legais ou com o seu
          consentimento explícito.
        </p>

        <h3>Cookies</h3>
        <p>
          Utilizamos cookies para melhorar a experiência do usuário em nosso
          site. Você pode gerenciar suas preferências de cookies nas
          configurações do seu navegador.
        </p>

        <h3>Segurança</h3>
        <p>
          Tomamos medidas para proteger seus dados pessoais contra acesso não
          autorizado ou divulgação. No entanto, lembre-se de que nenhuma
          transmissão pela Internet é 100% segura.
        </p>

        <h3>Contato</h3>
        <p>
          Se você tiver alguma dúvida sobre nossa Política de Privacidade, entre
          em contato conosco através do e-mail caveiraeventos23@gmail.com 
        </p>
        <h3>Fale Conosco</h3>
        <p>Entre em contato para qualquer dúvida, sugestão ou parceria.</p>
        <div>
          <h3>WhatsApp:</h3>
          (84) 99451-4529
        </div>
      </div>
    </div>
  );
}

export default Privacidade;
