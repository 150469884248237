import React from "react";
import './politicas.css';
export function Privacidade() {
  return (
    <div className="politicas">
      <div>
        <h1>Termos de Uso</h1>

        <h3>Introdução</h3>
        <p>
          Estes Termos de Uso descrevem os termos e condições de uso do site
          JiuJitsu Caveira. Ao usar este site, você concorda com os seguintes
          termos e condições. Leia atentamente antes de continuar a usar o site.
        </p>

        <h3>Uso Aceitável</h3>
        <p>
          Ao usar este site, você concorda em não realizar as seguintes ações:
        </p>
        <ul>
          <li>Violar quaisquer leis locais, nacionais ou internacionais;</li>
          <li>
            Publicar conteúdo ilegal, difamatório, ofensivo ou prejudicial;
          </li>
          <li>
            Violar os direitos autorais, marcas registradas ou outros direitos
            de propriedade intelectual;
          </li>
          <li>
            Realizar atividades que prejudiquem a segurança do site ou de seus
            usuários;
          </li>
          <li>
            Usar qualquer tipo de software malicioso, vírus ou código
            destrutivo.
          </li>
        </ul>

        <h3>Conteúdo do Usuário</h3>
        <p>
          Os usuários podem contribuir com conteúdo para este site, como
          comentários e postagens. Ao fazer isso, você concede a JiuJitsu
          Caveira uma licença para usar, modificar e exibir esse conteúdo de
          acordo com nossos termos.
        </p>

        <h3>Isenção de Responsabilidade</h3>
        <p>
          Este site é fornecido "como está" e não garantimos que ele estará
          livre de erros ou interrupções. Não nos responsabilizamos por
          quaisquer danos resultantes do uso deste site.
        </p>

        <h3>Alterações nos Termos</h3>
        <p>
          Reservamos o direito de alterar estes Termos de Uso a qualquer
          momento. As alterações serão efetivas assim que forem publicadas no
          site. É responsabilidade do usuário revisar periodicamente os termos
          atualizados.
        </p>

        <h3>Contato</h3>
        <p>
          Se você tiver alguma dúvida sobre nossos Termos de Uso, entre em
          contato conosco através do e-mail caveiraeventos23@gmail.com ou
          WhatsApp (84) 99451-4529 .
        </p>

        <p>Esta política foi atualizada em 23/09/2023.</p>
      </div>
    </div>
  );
}

export default Privacidade;
