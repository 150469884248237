import React, { useState } from "react";
import "./login.css";
import { useNavigate, Link } from "react-router-dom";

import { useAuth } from "../../services/context/AuthContext"; // Import useAuth

function LoginForm() {
  const [credentials, setCredentials] = useState({ email: "", password: "" });
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const { login } = useAuth();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCredentials((prevCredentials) => ({
      ...prevCredentials,
      [name]: value,
    }));
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setError(null);

    try {
      const response = await fetch(
        "https://jacquesgomes.com.br/FightEventsBackend/login",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(credentials),
        }
      );

      if (response.status === 401) {
        setError("Email e/ou senha estão incorretos.");
        return;
      } else if (!response.ok) {
        throw new Error("Authentication failed");
      }

      login();

      const data = await response.json();

      localStorage.setItem("token", data.token);
      localStorage.setItem("user", data.atleta_id);
      localStorage.setItem("tipoUsuario", data.perfil);

      navigate("/");
    } catch (error) {
      setError("Erro: " + error.message);
      console.error("Erro during login:", error);
    }
  };

  return (
    <div className="login__container">
      <div className="login-content">
        <form className="login-form" onSubmit={handleLogin}>
          <h3>Login</h3>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              name="email"
              required
              value={credentials.email}
              onChange={handleInputChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="password">Senha</label>
            <input
              type="password"
              id="password"
              name="password"
              required
              value={credentials.password}
              onChange={handleInputChange}
            />
          </div>

          {error && <div className="error-message">{error}</div>}

          <Link to="/esqueci-a-senha" style={{ marginTop: "10px", textDecoration: "underline" }}>
            Esqueceu a sua senha?
          </Link>



          <button type="submit" className="login-btn">
            ENTRAR
          </button>

          <div className="criar__conta">
            <h3>Novo por aqui?</h3>
            <Link to="/criar-conta">
              <button className="login-btn">CRIAR CONTA</button>
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
}

export default LoginForm;
