import React, { useState, useEffect } from 'react';
import  { Link } from 'react-router-dom';
import { EventCard } from './EventCard';
import './home.css';


export function Home() {

  const [events, setEvents] = useState([]);
  const [carregando, setCarregando] = useState(true);

  const main = "/";

  useEffect(() => {
    const apiUrl = 'https://jacquesgomes.com.br/FightEventsBackend/eventos';

    fetch(apiUrl, {
      method: 'GET'
    })
      .then((response) => response.json())
      .then((data) => setEvents(data))
      .then(() => {
        // Adicione um delay de 1000 milissegundos (1 segundo) antes de definir carregando como false
        setTimeout(() => {
          setCarregando(false);
        }, 1000);
      })
      .catch((error) => {
        console.error('Error fetching events:', error);
        // Set the events state to the tournamentData object
      });
  }, []);

  return (
    <div className="home">

      <section className='home__content'>

        <section className="events">

            {carregando ? (
            <p>Carregando...</p>
          ) : (
            <div className="event-list">
              {events && events.map((event) => (
                <Link to={`${main}events/${event.id}`} key={event.id}>
                  <EventCard key={event.id} event={event} />
                </Link>
                
              ))}
              </div>
           
          )}

        </section>

      </section>

      


    </div>
  );
}

export default Home;