import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./criar-conta.css";
import { useAuth } from "../../services/context/AuthContext";
import Carregando from '../utils/Carregando';

function SignupForm({ tipoUsuario, cpf }) {
  const { login } = useAuth();

  const [nome, setNome] = useState("");
  const [email, setEmail] = useState("");
  const [telefone, setTelefone] = useState("");
  const [professor, setProfessor] = useState("");
  const [academia, setAcademia] = useState("");
  const [instagram, setInstagram] = useState("");
  const [faixa, setFaixa] = useState("");
  const [sexo, setSexo] = useState("");
  const [peso, setPeso] = useState("");
  const [cidade, setCidade] = useState("");
  const [bairro, setBairro] = useState("");
  const [cep, setCep] = useState("");
  const [numero, setNumero] = useState("");
  const [estado, setEstado] = useState("");
  const [pais, setPais] = useState("");
  const [endereco, setEndereco] = useState("");
  const [necessidadesEspeciais, setNecessidadesEspeciais] = useState(false);
  const [especificacaoNecessidade, setEspecificacaoNecessidade] = useState("");
  const [carregando, setCarregando] = useState(false);

  const [mes, setMes] = useState("");
  const [dia, setDia] = useState("31");
  const [diaForm, setDiaForm] = useState("");
  const [ano, setAno] = useState("");

  const navigate = useNavigate();

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordAlert, setPasswordAlert] = useState("");

  const [aviso, setAviso] = useState(false);

  const toggleAviso = () =>{
setAviso(!aviso);
  };


  const currentYear = 2023;
  const numberOfYears = 100;

  const handleNecessidadesChange = (e) => {
    setNecessidadesEspeciais(e.target.checked);
  };

  const handleMesChange = (e) => {
    const selectedMes = e.target.value;
    setMes(selectedMes);
    setDiaForm("");
    if (selectedMes === "2") {
      setDia("28");
    } else if (["4", "6", "9", "11"].includes(selectedMes)) {
      setDia("30");
    } else {
      setDia("31");
    }
  };

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);

    if (newPassword === confirmPassword) {
      setPasswordAlert("");
    } else {
      setPasswordAlert("As senhas não coincidem!");
    }
  };

  const handleConfirmPasswordChange = (e) => {
    const newConfirmPassword = e.target.value;
    setConfirmPassword(newConfirmPassword);

    if (password === newConfirmPassword) {
      setPasswordAlert("");
    } else {
      setPasswordAlert("As senhas não coincidem!");
    }
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    setCarregando(true);

    const formData = {
      nome,
      email,
      password,
      cpf,
      data: diaForm + "/" + mes + "/" + ano,
      celular: telefone,
      professor,
      equipe: academia,
      graduacao: faixa,
      sexo,
      peso,
      cidade,
      bairro,
      cep,
      numero,
      estado,
      pais,
      perfil: tipoUsuario,
      endereco: endereco,
      instagram,
      necessidadesEspeciais,
      especificacaoNecessidade,

    };

    if (password === confirmPassword) {
      const response = await fetch(
        "https://jacquesgomes.com.br/FightEventsBackend/cadastrar",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );
      if (response.ok) {
        login();

        const data = await response.json();
        console.log(data);

        localStorage.setItem("token", data.token);
        localStorage.setItem("user", data.atleta_id);
        localStorage.setItem("tipoUsuario", data.perfil);
        setCarregando(false);

        navigate("/");
      } else {
        console.error("Error sending form data");
      }
    } else {
      alert("As senhas não coincidem. Por favor, tente novamente.");
    }
  };

  

  return (
    <div className="criar-conta">

      {carregando && <Carregando />}


      <div>
        <h2 className="title">CRIAR CONTA</h2>
      </div>

      <div className="criar__container">
        <form className="criar-form" onSubmit={handleSubmit}>
          <div className="criar-content">
            <h3>Dados pessoais</h3>

            <div className="form-group">
              <label htmlFor="nome">Nome*</label>
              <input
                required
                type="text"
                id="nome"
                value={nome}
                onChange={(e) => setNome(e.target.value)}
              />
            </div>

            <div className="form-group">
              <label htmlFor="email">Email*</label>
              <input
                required
                type="text"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>

            <div className="form-group">
              <label htmlFor="password">Senha*</label>
              <input
                required
                type="password"
                id="password"
                value={password}
                onChange={handlePasswordChange}
              />
            </div>

            <div className="form-group">
              <label htmlFor="confirmPassword">Confirmar Senha*</label>
              <input
                required
                type="password"
                id="confirmPassword"
                value={confirmPassword}
                onChange={handleConfirmPasswordChange}
              />
            </div>

            <div>
              <p style={{ color: "red" }}>{passwordAlert}</p>
            </div>

            <div className="form-group">
              <label htmlFor="cpf">CPF*</label>
              <input
                required
                type="text"
                placeholder="Somente números, ex: 98712345629"
                id="cpf"
                value={cpf}
                readOnly
              />
            </div>

            <div className="form-group">
              <label htmlFor="telefone">Celular*</label>
              <input
                required
                type="tel"
                id="telefone"
                value={telefone}
                onChange={(e) => setTelefone(e.target.value)}
              />
            </div>

            <div className="data-container">
              Data de nascimento*
              <div className="form-group data-nascimento">
                <span>
                  <label htmlFor="mes">Mês:</label>
                  <select id="mes" value={mes} onChange={handleMesChange}>
                    <option value=""></option>
                    <option value="1">Janeiro</option>
                    <option value="2">Fevereiro</option>
                    <option value="3">Março</option>
                    <option value="4">Abril</option>
                    <option value="5">Maio</option>
                    <option value="6">Junho</option>
                    <option value="7">Julho</option>
                    <option value="8">Agosto</option>
                    <option value="9">Setembro</option>
                    <option value="10">Outubro</option>
                    <option value="11">Novembro</option>
                    <option value="12">Dezembro</option>
                  </select>
                </span>
                <span>
                  <label htmlFor="dia">Dia:</label>
                  <select
                    id="dia"
                    value={diaForm}
                    onChange={(e) => setDiaForm(e.target.value)}
                  >
                    <option value=""></option>
                    {dia &&
                      [...Array(Number(dia))].map((_, index) => (
                        <option key={index + 1} value={index + 1}>
                          {index + 1}
                        </option>
                      ))}
                  </select>
                </span>
                <span>
                  <label htmlFor="dia">Ano:</label>
                  <select
                    id="ano"
                    value={ano}
                    onChange={(e) => setAno(e.target.value)}
                  >
                    <option value=""></option>
                    {currentYear &&
                      [...Array(numberOfYears)].map((_, index) => (
                        <option
                          key={currentYear - index}
                          value={currentYear - index}
                        >
                          {currentYear - index}
                        </option>
                      ))}
                  </select>
                </span>
              </div>
            </div>
          </div>

          <div className="criar-content">
            {tipoUsuario === 'atleta' ? (
              <>

            <h3>Sobre o esporte</h3>

<div className="form-group">
  <label htmlFor="professor">Professor*</label>
  <input
    required
    type="text"
    id="professor"
    value={professor}
    onChange={(e) => setProfessor(e.target.value)}
  />
</div>

{/* <div className="form-group">
  <label htmlFor="academia">Academia*</label>
  <input
    required
    type="text"
    id="academia"
    value={academia}
    onChange={(e) => setAcademia(e.target.value)}
  />
</div> */}

<div className="form-group">
  <label htmlFor="faixa">Faixa*</label>
  <select
    required
    id="faixa"
    value={faixa}
    onChange={(e) => setFaixa(e.target.value)}
  >
    <option value="">Selecione</option>

    <option value="Faixa Branca">Faixa Branca</option>
    <option value="Faixa Cinza">Faixa Cinza</option>
    <option value="Faixa Amarela">Faixa Amarela</option>
    <option value="Faixa Laranja">Faixa Laranja</option>
    <option value="Faixa Verde">Faixa Verde</option>
    <option value="Faixa Azul">Faixa Azul</option>
    <option value="Faixa Roxa">Faixa Roxa</option>
    <option value="Faixa Marrom">Faixa Marrom</option>
    <option value="Faixa Preta">Faixa Preta</option>
    <option value="Faixa Coral">Faixa Coral</option>
    <option value="Faixa Vermelha">Faixa Vermelha</option>
  </select>
</div>

<div className="form-group">
  <label htmlFor="sexo">Sexo*</label>
  <select
    required
    id="sexo"
    value={sexo}
    onChange={(e) => setSexo(e.target.value)}
  >
    <option value="">Selecione</option>
    <option value="masculino">Masculino</option>
    <option value="feminino">Feminino</option>
  </select>
</div>

<div className="form-group">
  <label htmlFor="instagram">Instagram (opcional)</label>
  <a style={{color: 'gray', fontSize: '14px'}} href className="aviso-btn-insta" onClick={toggleAviso}><strong>Aviso</strong> {aviso ? '-' : '+'} </a>

{aviso ? <p style={{color: 'gray', fontSize: '12px'}}><strong>Atenção: </strong>exibiremos o seu instagram em listagens de lutas casadas, só preencha caso concorde em expor essa informação.</p> : <></>}

  <input
    type="text"
    id="instagram"
    value={instagram}
    onChange={(e) => setInstagram(e.target.value)}
  />
</div>
{/* 
<div className="form-group">
  <label htmlFor="peso">Peso*</label>
  <input
    required
    placeholder='Ex.: "85", "85.50"'
    type="number"
    min="0"
    step="0.01"
    id="peso"
    value={peso}
    onChange={(e) => setPeso(e.target.value)}
  />
</div> */}

<div className="form-group">
  <label className="need" htmlFor="necessidadesEspeciais">
    Tem uma necessidade especial?
    <input
      id="necessidadesEspeciais"
      type="checkbox"
      checked={necessidadesEspeciais}
      onChange={handleNecessidadesChange}
      className="checkbox"
    />
  </label>
</div>


{necessidadesEspeciais && (
  <div className="form-group">
    <label htmlFor="especificacaoNecessidade">Especifique:</label>
    <input
      type="text"
      id="especificacaoNecessidade"
      value={especificacaoNecessidade}
      onChange={(e) => setEspecificacaoNecessidade(e.target.value)}
    />
  </div>
)}
              </>
            ):(<>

<h3>Endereço</h3>

    <div className="form-group">
      <label htmlFor="endereco">Endereço*</label>
      <input
        required
        type="text"
        id="endereco"
        value={endereco}
        onChange={(e) => setEndereco(e.target.value)}
      />
    </div>

    <div className="form-group">
      <label htmlFor="numero">Número*</label>
      <input
        required
        type="text"
        id="numero"
        value={numero}
        onChange={(e) => setNumero(e.target.value)}
      />
    </div>

    <div className="form-group">
      <label htmlFor="pais">País*</label>
      <input
        required
        type="text"
        id="pais"
        value={pais}
        onChange={(e) => setPais(e.target.value)}
      />
    </div>

    <div className="form-group">
      <label htmlFor="estado">Estado*</label>
      <input
        required
        type="text"
        id="estado"
        value={estado}
        onChange={(e) => setEstado(e.target.value)}
      />
    </div>

    <div className="form-group">
      <label htmlFor="cidade">Cidade*</label>
      <input
        required
        type="text"
        id="cidade"
        value={cidade}
        onChange={(e) => setCidade(e.target.value)}
      />
    </div>

    <div className="form-group">
      <label htmlFor="bairro">Bairro*</label>
      <input
        required
        type="text"
        id="bairro"
        value={bairro}
        onChange={(e) => setBairro(e.target.value)}
      />
    </div>

    <div className="form-group">
      <label htmlFor="cep">CEP*</label>
      <input
        required
        type="text"
        id="cep"
        value={cep}
        onChange={(e) => setCep(e.target.value)}
      />
    </div>



            
            </>) }
            
          </div>
{tipoUsuario === 'atleta' &&
          <div className="criar-content endereco__container">
            <h3>Endereço</h3>

            <div className="endereco">
              <div className="endereco-child">
                <div className="form-group">
                  <label htmlFor="endereco">Endereço*</label>
                  <input
                    required
                    type="text"
                    id="endereco"
                    value={endereco}
                    onChange={(e) => setEndereco(e.target.value)}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="numero">Número*</label>
                  <input
                    required
                    type="text"
                    id="numero"
                    value={numero}
                    onChange={(e) => setNumero(e.target.value)}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="pais">País*</label>
                  <input
                    required
                    type="text"
                    id="pais"
                    value={pais}
                    onChange={(e) => setPais(e.target.value)}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="estado">Estado*</label>
                  <input
                    required
                    type="text"
                    id="estado"
                    value={estado}
                    onChange={(e) => setEstado(e.target.value)}
                  />
                </div>
              </div>

              <div className="endereco-child">

                <div className="form-group">
                  <label htmlFor="cidade">Cidade*</label>
                  <input
                    required
                    type="text"
                    id="cidade"
                    value={cidade}
                    onChange={(e) => setCidade(e.target.value)}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="bairro">Bairro*</label>
                  <input
                    required
                    type="text"
                    id="bairro"
                    value={bairro}
                    onChange={(e) => setBairro(e.target.value)}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="cep">CEP*</label>
                  <input
                    required
                    type="text"
                    id="cep"
                    value={cep}
                    onChange={(e) => setCep(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </div>}

          <p>Os campos com '*' são de preenchimento obrigatório.</p>

          <div className="criar-btn">
            <button type="submit">Criar Conta</button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default SignupForm;
