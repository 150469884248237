import React, { useState, useEffect } from 'react';

function SignupForm({dadosAtleta, mudarDadosAtleta, index}) {
  const atletaId = dadosAtleta.id;
  const [nome, setNome] = useState('');
  const [cpf, setCpf] = useState('');
  const [professor, setProfessor] = useState('');
  const [academia, setAcademia] = useState('');
  const [faixa, setFaixa] = useState('');
  const [peso, setPeso] = useState('');

  // const [sexo, setSexo] = useState('');
  // const [necessidadesEspeciais, setNecessidadesEspeciais] = useState(false);
  // const [especificacaoNecessidade, setEspecificacaoNecessidade] = useState('');
  // const [mes, setMes] = useState('');
  // const [dia, setDia] = useState('31');
  // const [diaForm, setDiaForm] = useState('');
  // const [ano, setAno] = useState('');
  // const nomeFicticio = "Nome Fictício";
  // const currentYear = 2023;
  // const numberOfYears = 100;

  // const handleNecessidadesChange = (e) => {
  //   setNecessidadesEspeciais(e.target.checked);
  // };

  // const handleMesChange = (e) => {
  //   const selectedMes = e.target.value;
  //   setMes(selectedMes);
  //   setDiaForm('');
  //   if (selectedMes === '2') {
  //     setDia('28');
  //   } else if (['4', '6', '9', '11'].includes(selectedMes)) {
  //     setDia('30');
  //   } else {
  //     setDia('31');
  //   }
  // };

  // const handleCPFInputChange = (e) => {
  //   const inputValue = e.target.value.replace(/[^0-9]/g, '');

  //   if (inputValue.length <= 11) {
  //     setCpf(inputValue);
  //   }
  // };

  const handleSubmit = (e) => {
    e.preventDefault();

    const formData = {
      nomeCompleto: nome,
      cpf,
      //data: diaForm + '/' + mes + '/' + ano,
      professor,
      equipe: academia,
      graduacao: faixa,
      peso,
      // necessidadesEspeciais,
      // especificacaoNecessidade,
    };

    console.log(formData);

    fetch('https://jacquesgomes.com.br/FightEventsBackend/atleta/' + atletaId, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    })
      .then((response) => {
        if (response.ok) {

          mudarDadosAtleta(formData, index);
          
          alert('Alterações feitas com sucesso!');
          
        } else {
          console.error('Error sending form data');
        }
      })
      .catch((error) => {
        console.error('Error sending form data:', error);
      });
  };

  useEffect(() => {
    if (dadosAtleta) {
      const {
        nomeCompleto,
        cpf,
        professor,
        equipe,
        graduacao,
        peso,
      } = dadosAtleta;

      setNome(nomeCompleto);
      setCpf(cpf);
      setProfessor(professor);
      setAcademia(equipe);
      setFaixa(graduacao);
      setPeso(peso);
    }
  }, [dadosAtleta]);

  return (
    <div className='criar-conta alterar-atleta'>
      <div>
        <form  onSubmit={handleSubmit}>

            <h3>Alterar Dados</h3>
<div>
            <div className="form-group">
              <label htmlFor="nome">Nome*</label>
              <input
                 
                type="text"
                id="nome"
                value={nome}
                onChange={(e) => setNome(e.target.value)}
              />
            </div>
            
            {/* <div className="form-group">
              <label htmlFor="cpf">CPF*</label>
              <input
                 
                type="text"
                placeholder='Somente números, ex: 98712345629'
                id="cpf"
                value={cpf}
                onChange={(e) => handleCPFInputChange(e)}
              />
            </div> */}



            {/* <div className='data-container'>
              Data de nascimento*
              <div className="form-group data-nascimento">
                <span>
                  <label htmlFor="mes">Mês:</label>
                  <select
                    id="mes"
                    value={mes}
                    onChange={handleMesChange}
                  >
                    <option value=""></option>
                    <option value="1">Janeiro</option>
                    <option value="2">Fevereiro</option>
                    <option value="3">Março</option>
                    <option value="4">Abril</option>
                    <option value="5">Maio</option>
                    <option value="6">Junho</option>
                    <option value="7">Julho</option>
                    <option value="8">Agosto</option>
                    <option value="9">Setembro</option>
                    <option value="10">Outubro</option>
                    <option value="11">Novembro</option>
                    <option value="12">Dezembro</option>
                  </select>
                </span>
                <span>
                  <label htmlFor="dia">Dia:</label>
                  <select
                    id="dia"
                    value={diaForm}
                    onChange={(e) => setDiaForm(e.target.value)}
                  >
                    <option value=""></option>
                    {dia && [...Array(Number(dia))].map((_, index) => (
                      <option key={index + 1} value={index + 1}>
                        {index + 1}
                      </option>
                    ))}
                  </select>
                </span>
                <span>
                  <label htmlFor="dia">Ano:</label>
                  <select
                    id="ano"
                    value={ano}
                    onChange={(e) => setAno(e.target.value)}
                  >
                    <option value=""></option>
                    {currentYear &&
                      [...Array(numberOfYears)].map((_, index) => (
                        <option key={currentYear - index} value={currentYear - index}>
                          {currentYear - index}
                        </option>
                      ))}
                  </select>
                </span>
              </div>
            </div> */}

            <div className="form-group">
              <label htmlFor="professor">Professor*</label>
              <input
                 
                type="text"
                id="professor"
                value={professor}
                onChange={(e) => setProfessor(e.target.value)}
              />
            </div>
{/*             
            <div className="form-group">
              <label htmlFor="academia">Academia*</label>
              <input
                 
                type="text"
                id="academia"
                value={academia}
                onChange={(e) => setAcademia(e.target.value)}
              />
            </div> */}
            <div className="form-group">
              <label htmlFor="faixa">Faixa*</label>
              <select
                 
                id="faixa"
                value={faixa}
                onChange={(e) => setFaixa(e.target.value)}
              >
                <option value="">Selecione</option>
                <option value="Faixa Branca">Faixa Branca</option>
                <option value="Faixa Cinza">Faixa Cinza</option>
                <option value="Faixa Amarela">Faixa Amarela</option>
                <option value="Faixa Laranja">Faixa Laranja</option>
                <option value="Faixa Verde">Faixa Verde</option>
                <option value="Faixa Azul">Faixa Azul</option>
                <option value="Faixa Roxa">Faixa Roxa</option>
                <option value="Faixa Marrom">Faixa Marrom</option>
                <option value="Faixa Preta">Faixa Preta</option>
                <option value="Faixa Coral">Faixa Coral</option>
                <option value="Faixa Vermelha">Faixa Vermelha</option>
              </select>
            </div>

            {/* <div className="form-group">
              <label htmlFor="sexo">Sexo*</label>
              <select
                 
                id="sexo"
                value={sexo}
                onChange={(e) => setSexo(e.target.value)}
              >
                <option value="">Selecione</option>
                <option value="masculino">Masculino</option>
                <option value="feminino">Feminino</option>
              </select>
            </div> */}

            {/* <div className="form-group">
              <label htmlFor="peso">Peso*</label>
              <input
                 
                placeholder='Ex.: "85", "85.50"'
                type="number"
                min="0"
                step="0.01"
                id="peso"
                value={peso}
                onChange={(e) => setPeso(e.target.value)}
              />
            </div> */}

            {/* <div className="form-group">
              <label htmlFor="necessidadesEspeciais">
                Tem uma necessidade especial?
                <input
                  id="necessidadesEspeciais"
                  type="checkbox"
                  checked={necessidadesEspeciais}
                  onChange={handleNecessidadesChange}
                />
              </label>
            </div>
            {necessidadesEspeciais && (
              <div className="form-group">
                <label htmlFor="especificacaoNecessidade">Especifique:</label>
                <input
                  type="text"
                  id="especificacaoNecessidade"
                  value={especificacaoNecessidade}
                  onChange={(e) => setEspecificacaoNecessidade(e.target.value)}
                />
              </div>
            )}
             */}
            </div>

          <div className='criar-btn'><button type="submit">Confirmar Alterações</button></div>
          
        </form>
      </div>
    </div>
  );
}

export default SignupForm;
