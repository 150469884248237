import React, { useState } from "react";
import { getFighterByCPF } from "../login/getFighterByCPF";

function SignupForm(props) {
  const [error, setError] = useState("");
  const [nome, setNome] = useState("");
  const [cpf, setCpf] = useState("");
  const [professor, setProfessor] = useState("");
  const [academia, setAcademia] = useState("");
  const [faixa, setFaixa] = useState("");
  const [instagram, setInstagram] = useState("");
  const [sexo, setSexo] = useState("");
  const [peso, setPeso] = useState("");
  const [necessidadesEspeciais, setNecessidadesEspeciais] = useState(false);
  const [especificacaoNecessidade, setEspecificacaoNecessidade] = useState("");

  const [mes, setMes] = useState("");
  const [dia, setDia] = useState("31");
  const [diaForm, setDiaForm] = useState("");
  const [ano, setAno] = useState("");
  const [aviso, setAviso] = useState(false);

  const toggleAviso = () =>{
setAviso(!aviso);
  };


  const nomeFicticio = "Nome Fictício";

  const currentYear = 2023;
  const numberOfYears = 100;

  const handleNecessidadesChange = (e) => {
    setNecessidadesEspeciais(e.target.checked);
  };

  const handleMesChange = (e) => {
    const selectedMes = e.target.value;
    setMes(selectedMes);
    setDiaForm("");
    if (selectedMes === "2") {
      setDia("28");
    } else if (["4", "6", "9", "11"].includes(selectedMes)) {
      setDia("30");
    } else {
      setDia("31");
    }
  };

  const handleCPFInputChange = (e) => {
    const inputValue = e.target.value.replace(/[^0-9]/g, "");

    if (inputValue.length <= 11) {
      setCpf(inputValue);
    }
  };

  const handleSubmit = async (e) => {
    const userId = localStorage.getItem("user");

    e.preventDefault();

    const fetchedFighter = await getFighterByCPF(cpf);

    if (fetchedFighter) {
      setError("CPF duplicado, por favor insira outro.");
      return;
    }

    const formData = {
      responsavelId: userId,
      nomeCompleto: nome,
      cpf,
      data: diaForm + "/" + mes + "/" + ano,
      professor,
      equipe: academia,
      graduacao: faixa,
      sexo,
      peso,
      perfil: "atleta",
      necessidadesEspeciais,
      especificacaoNecessidade,
      email: props.email,
    };

    console.log(formData);

    fetch("https://jacquesgomes.com.br/FightEventsBackend/atletas", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then((response) => {
        if (response.ok) {
          alert("Atleta criado com sucesso!");
          props.onCadastroConcluido();
          props.adicionarNovoAtleta(formData);
        } else {
          console.error("Error sending form data");
        }
      })
      .catch((error) => {
        console.error("Error sending form data:", error);
      });
  };

  return (
    <div className="criar-conta">
      <div className="criar-btn">
        <br></br>
        <p>
          ATENÇÃO: As comunicações do novo atleta serão feitas diretamente com o
          responsável <strong>'{nomeFicticio}'</strong>, através dos dados
          cadastrados (email, celular).
        </p>
        <div>
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="nome">Nome*</label>
              <input
                required
                type="text"
                id="nome"
                value={nome}
                onChange={(e) => setNome(e.target.value)}
              />
            </div>

            <div className="form-group">
              <label htmlFor="cpf">CPF*</label>
              <input
                required
                type="text"
                placeholder="Somente números, ex: 98712345629"
                id="cpf"
                value={cpf}
                onChange={(e) => handleCPFInputChange(e)}
              />
            </div>

            <div className="data-container">
              Data de nascimento*
              <div className="form-group data-nascimento">
                <span>
                  <label htmlFor="mes">Mês:</label>
                  <select id="mes" value={mes} onChange={handleMesChange}>
                    <option value=""></option>
                    <option value="1">Janeiro</option>
                    <option value="2">Fevereiro</option>
                    <option value="3">Março</option>
                    <option value="4">Abril</option>
                    <option value="5">Maio</option>
                    <option value="6">Junho</option>
                    <option value="7">Julho</option>
                    <option value="8">Agosto</option>
                    <option value="9">Setembro</option>
                    <option value="10">Outubro</option>
                    <option value="11">Novembro</option>
                    <option value="12">Dezembro</option>
                  </select>
                </span>
                <span>
                  <label htmlFor="dia">Dia:</label>
                  <select
                    id="dia"
                    value={diaForm}
                    onChange={(e) => setDiaForm(e.target.value)}
                  >
                    <option value=""></option>
                    {dia &&
                      [...Array(Number(dia))].map((_, index) => (
                        <option key={index + 1} value={index + 1}>
                          {index + 1}
                        </option>
                      ))}
                  </select>
                </span>
                <span>
                  <label htmlFor="ano">Ano:</label>
                  <select
                    id="ano"
                    value={ano}
                    onChange={(e) => setAno(e.target.value)}
                  >
                    <option value=""></option>
                    {currentYear &&
                      [...Array(numberOfYears)].map((_, index) => (
                        <option
                          key={currentYear - index}
                          value={currentYear - index}
                        >
                          {currentYear - index}
                        </option>
                      ))}
                  </select>
                </span>
              </div>
            </div>

            <div className="form-group">
              <label htmlFor="professor">Professor*</label>
              <input
                required
                type="text"
                id="professor"
                value={professor}
                onChange={(e) => setProfessor(e.target.value)}
              />
            </div>

            {/* <div className="form-group">
              <label htmlFor="academia">Academia*</label>
              <input
                required
                type="text"
                id="academia"
                value={academia}
                onChange={(e) => setAcademia(e.target.value)}
              />
            </div> */}

            <div className="form-group">
              <label htmlFor="faixa">Faixa*</label>
              <select
                required
                id="faixa"
                value={faixa}
                onChange={(e) => setFaixa(e.target.value)}
              >
                <option value="">Selecione</option>
                <option value="Faixa Branca">Faixa Branca</option>
                <option value="Faixa Cinza">Faixa Cinza</option>
                <option value="Faixa Amarela">Faixa Amarela</option>
                <option value="Faixa Laranja">Faixa Laranja</option>
                <option value="Faixa Verde">Faixa Verde</option>
                <option value="Faixa Azul">Faixa Azul</option>
                <option value="Faixa Roxa">Faixa Roxa</option>
                <option value="Faixa Marrom">Faixa Marrom</option>
                <option value="Faixa Preta">Faixa Preta</option>
                <option value="Faixa Coral">Faixa Coral</option>
                <option value="Faixa Vermelha">Faixa Vermelha</option>
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="sexo">Sexo*</label>
              <select
                required
                id="sexo"
                value={sexo}
                onChange={(e) => setSexo(e.target.value)}
              >
                <option value="">Selecione</option>
                <option value="masculino">Masculino</option>
                <option value="feminino">Feminino</option>
              </select>
            </div>

            <div className="form-group">
              <label htmlFor="instagram">Instagram (opcional)</label>
              <a style={{color: 'gray', fontSize: '14px'}} href className="aviso-btn-insta" onClick={toggleAviso}><strong>Aviso</strong> {aviso ? '-' : '+'} </a>

          {aviso ? <p style={{color: 'gray', fontSize: '12px'}}><strong>Atenção: </strong>exibiremos o seu instagram em listagens de lutas casadas, só preencha caso concorde em expor essa informação.</p> : <></>}
              <input
                type="text"
                id="instagram"
                value={instagram}
                onChange={(e) => setInstagram(e.target.value)}
              />
            </div>

            {/* <div className="form-group">
              <label htmlFor="peso">Peso*</label>
              <input
                required
                placeholder='Ex.: "85", "85.50"'
                type="number"
                min="0"
                step="0.01"
                id="peso"
                value={peso}
                onChange={(e) => setPeso(e.target.value)}
              />
            </div> */}

            <div className="form-group">
              <label htmlFor="necessidadesEspeciais">
                Tem uma necessidade especial?
                <input
                  id="necessidadesEspeciais"
                  type="checkbox"
                  checked={necessidadesEspeciais}
                  onChange={handleNecessidadesChange}
                />
              </label>
            </div>

            {necessidadesEspeciais && (
              <div className="form-group">
                <label htmlFor="especificacaoNecessidade">Especifique:</label>
                <input
                  type="text"
                  id="especificacaoNecessidade"
                  value={especificacaoNecessidade}
                  onChange={(e) => setEspecificacaoNecessidade(e.target.value)}
                />
              </div>
            )}

            <p>Os campos com '*' são de preenchimento obrigatório.</p>

            {error && (
              <p
                style={{ color: "red", marginTop: "5px", marginBottom: "5px" }}
              >
                {error}
              </p>
            )}

            <div className="criar-btn cadastrar-btn">
              <button
                style={{ marginRight: "10px" }}
                onClick={props.onCadastroConcluido}
              >
                Voltar
              </button>
              <button type="submit">Criar Conta</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default SignupForm;
