import React, { useState, useEffect } from "react";
import TermosCondicoesServico from "../Termos";

const ConfirmarDados = ({ data, onFormDataChange, athleteId, onBack }) => {
  const [showTermos, setShowTermos] = useState(false);
  const [categorias, setCategorias] = useState([]);
  const [academias, setAcademias] = useState([]);
  const [aviso, setAviso] = useState(false);

  const toggleAviso = () =>{
setAviso(!aviso);
  };

  const [formData, setFormData] = useState({
    equipe: "",
    peso: "",
    graduacao: "",
    categoria: "",
    professor: "",
    ingresso: "",
    concordouTermos: false,
    email: "",
    sexo: "",
    nome: "",
    cpf: "",
    instagram: "",
  });



  const categoriesData = {
    '4/5': { Galo: 14.7, Pluma: 17.9, Pena: 20, Leve: 23, Médio: 26, 'Meio-pesado': 29, Pesado: 32, 'Super Pesado': 35 },
    '6/7': { Galo: 18.2, Pluma: 21, Pena: 24, Leve: 27.2, Médio: 30.2, 'Meio-pesado': 33.2, Pesado: 36.2, 'Super Pesado': 39.3 },
    '8/9': { Galo: 24, Pluma: 27, Pena: 30.2, Leve: 33.2, Médio: 36.2, 'Meio-pesado': 39.2, Pesado: 42.3, 'Super Pesado': 45.3 },
    '10/11': { Galo: 32.2, Pluma: 36.2, Pena: 40.3, Leve: 44.3, Médio: 48.3, 'Meio-pesado': 52.5, Pesado: 56.5, 'Super Pesado': 60.5 },
    '12/13': { Galo: 36.2, Pluma: 40.3, Pena: 44.3, Leve: 48.3, Médio: 52.3, 'Meio-pesado': 56.5, Pesado: 60.5, 'Super Pesado': 65}
  };

  const DadosDePeso = {
    "14/15": {
      "masculino": {
        "Galo": 44.3,
        "Pluma": 48.3,
        "Pena": 52.5,
        "Leve": 56.5,
        "Médio": 60.5,
        "Meio-pesado": 65,
        "Pesado": 69,
        "Super Pesado": 73
      },
      "feminino": {
        "Galo": 40.3,
        "Pluma": 44.3,
        "Pena": 48.3,
        "Leve": 52.5,
        "Médio": 56.5,
        "Meio-pesado": 60.5,
        "Pesado": 65,
        "Super Pesado": 69
      }
    },
    "16/17": {
      "masculino": {
        "Galo": 53.5,
        "Pluma": 58.5,
        "Pena": 64,
        "Leve": 69,
        "Médio": 74,
        "Meio-pesado": 79.3,
        "Pesado": 84.3,
        "Super Pesado": 89.3
      },
      "feminino": {
        "Galo": 44.3,
        "Pluma": 48.3,
        "Pena": 52.5,
        "Leve": 56.5,
        "Médio": 60.5,
        "Meio-pesado": 65,
        "Pesado": 69,
        "Super Pesado": 73
      }
    },
    "+18": {
      "masculino": {
        "Galo": 57.5,
        "Pluma": 64,
        "Pena": 70,
        "Leve": 76,
        "Médio": 82.3,
        "Meio-pesado": 88.3,
        "Pesado": 94.3,
        "Super Pesado": 100.5
      },
      "feminino": {
        "Galo": 48.5,
        "Pluma": 53.5,
        "Pena": 58.5,
        "Leve": 64,
        "Médio": 69,
        "Meio-pesado": 74,
        "Pesado": 79.3,
        "Super Pesado": 84.3
      }
    }
  };
  const calculateCategories = (idade, sexo) => {
    if (idade < 14) {
      const categoryKey = Object.keys(categoriesData).find(key => key.includes(idade.toString()));
      if (categoryKey) {
        const categoryWeights = categoriesData[categoryKey];
        const categoria = categoryKey;
        const x = Object.keys(categoryWeights).map(categoriaPeso => `${categoria} - ${categoriaPeso}: até ${categoryWeights[categoriaPeso].toFixed(1)} kg`);
        x.push(`${categoria} - Pesadíssimo: +${(categoryWeights["Super Pesado"]).toFixed(3)} kg`);
        return x;
      }
    } else if (idade >= 14 && idade <= 15) {
      const categoria = "14/15";
      const categoryWeights = DadosDePeso[categoria][sexo];
      const superPesado = categoryWeights["Super Pesado"] ;
      const x = Object.keys(categoryWeights).map(categoriaPeso => {
        if (categoriaPeso === 'Super Pesado') {
          return [`${categoria} - ${categoriaPeso}: até ${categoryWeights[categoriaPeso].toFixed(3)} kg`, `Pesadíssimo: +${superPesado.toFixed(3)} kg`];
        } else {
          return `${categoria} - ${categoriaPeso}: até ${categoryWeights[categoriaPeso].toFixed(3)} kg`;
        }
      }).flat();
      return x;
    } else if (idade >= 16 && idade <= 17) {
      const categoria = "16/17";
      const categoryWeights = DadosDePeso[categoria][sexo];
      const superPesado = categoryWeights["Super Pesado"] ;
      const x = Object.keys(categoryWeights).map(categoriaPeso => {
        if (categoriaPeso === 'Super Pesado') {
          return [`${categoria} - ${categoriaPeso}: até ${categoryWeights[categoriaPeso].toFixed(3)} kg`, `${categoria} - Pesadíssimo: +${superPesado.toFixed(3)} kg`];
        } else {
          return `${categoria} - ${categoriaPeso}: até ${categoryWeights[categoriaPeso].toFixed(3)} kg`;
        }
      }).flat();
      return x;
    } else if (idade >= 18) {
      const categoria = "+18";
      const categoryWeights = DadosDePeso[categoria][sexo];
      const superPesado = categoryWeights["Super Pesado"];
      const x = Object.keys(categoryWeights).map(categoriaPeso => {
        if (categoriaPeso === 'Super Pesado') {
          return [`${categoria} - ${categoriaPeso}: até ${categoryWeights[categoriaPeso].toFixed(3)} kg`, `Pesadíssimo: +${superPesado.toFixed(3)} kg`];
        } else {
          return `${categoria} - ${categoriaPeso}: até ${categoryWeights[categoriaPeso].toFixed(3)} kg`;
        }
      }).flat();
      return x;
    }
    return [];
  };
  
  const ingressos = data.ingressos.split(",");

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === "checkbox" ? checked : value;
    setFormData({
      ...formData,
      [name]: newValue,
    });
  };

  const handleCategoriaChange = (e) => {
    const { value } = e.target;
    setFormData({
      ...formData,
      categoria: value,
    });
  };

  const handleAcademiaChange = (e) => {
    const { value } = e.target;
    setFormData({
      ...formData,
      equipe: value,
    });
  };
  
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchAthleteData = async (athleteId) => {
      try {
        setLoading(true);

        const response = await fetch(
          `https://jacquesgomes.com.br/FightEventsBackend/atletas-academias/` + athleteId
        );

        if (!response.ok) {
          throw new Error(`Request failed with status: ${response.status}`);
        }

        const dadosAdicionais = await response.json();

        const result = calculateCategories(dadosAdicionais.age, dadosAdicionais.sexo);
        setCategorias((prevCategories) => [...prevCategories, ...result]);

        setAcademias(dadosAdicionais.academias || []);
  
        setFormData((prevFormData) => ({
          ...prevFormData,
          nome: dadosAdicionais.nomeCompleto || "",
          peso: dadosAdicionais.peso || "",
          graduacao: dadosAdicionais.graduacao || "",
          professor: dadosAdicionais.professor || "",
          idade: dadosAdicionais.age || "",
          email: dadosAdicionais.email || "",
          sexo: dadosAdicionais.sexo || "",
          cpf: dadosAdicionais.cpf || "",
          instagram: dadosAdicionais.instagram || "",
          
        }));
      } catch (error) {
        

        console.error("Error fetching athlete data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchAthleteData(athleteId);
  }, [athleteId]);

  const handleSubmit = (e, formData) =>{
    e.preventDefault();
    
    const { equipe, graduacao, concordouTermos, ingresso, categoria } = formData;

    if(!equipe || !ingresso || concordouTermos===false || !ingresso || !graduacao || !categoria){
      alert("Preencha todos os campos.");
    } else{
      onFormDataChange(formData);
    }

    }

  return (
    <div className="confirmar-container">
      {loading ? (
        <p>Carregando...</p>
      ) : (
        <form onSubmit={(e) => handleSubmit(e, formData)}>
          <h3 className="title-modal">{data.title}</h3>
          <div className="form-group">
            <h3>Confirme seus dados</h3>

          </div>

          <div className="form-group">
            <label htmlFor="academia">Academia:</label>
            <select
              id="academia"
              name="academia"
              value={formData.equipe}
              onChange={handleAcademiaChange}
            >
              <option value="selecione">Selecione</option>
              {academias.map((academia, index) => (
                <option key={index} value={academia}>
                  {academia}
                </option>
              ))}
            </select>
          </div>

          <div className="form-group">
            <label htmlFor="categoria">Categoria:</label>
            <select value={formData.categoria} onChange={handleCategoriaChange}>
            <option value="" >Escolha uma categoria</option>
            {categorias.map((categoria, index) => (
              <option key={index} value={categoria}>
                {categoria}
              </option>
            ))}
          </select>
          </div>

          {/* <div className="form-group">
            <label htmlFor="idade">Idade:</label>
            <input
              style={{
                backgroundColor: "lightgrey",
                border: "1px solid black",
              }}
              type="text"
              id="idade"
              name="idade"
              value={formData.idade}
              readOnly={true}
            />
          </div> */}

          <div className="form-group">
            <label htmlFor="professor">Professor:</label>
            <input
              type="text"
              id="professor"
              name="professor"
              value={formData.professor}
              onChange={handleInputChange}
            />
          </div>

          <div className="form-group">
            <label style={{paddingBottom: '0'}} htmlFor="instagram">
Instagram (opcional):</label>

              <a style={{color: 'gray', fontSize: '14px'}} href className="aviso-btn-insta" onClick={toggleAviso}><strong>Aviso</strong> {aviso ? '-' : '+'} </a>

          {aviso ? <p style={{color: 'gray', fontSize: '12px'}}><strong>Atenção: </strong>exibiremos o seu instagram em listagens de lutas casadas, só preencha caso concorde em expor essa informação.</p> : <></>}
            
            <input
              type="text"
              id="instagram"
              name="instagram"
              value={formData.instagram}
              onChange={handleInputChange}
            />
          </div>
          
          <div className="form-group">
            <label htmlFor="graduacao">Graduação:</label>
            <select
              id="graduacao"
              name="graduacao"
              value={formData.graduacao}
              onChange={handleInputChange}
            >
              <option value={formData.graduacao}>{formData.graduacao}</option>
              <option value="Faixa Branca">Faixa Branca</option>
              <option value="Faixa Cinza">Faixa Cinza</option>
              <option value="Faixa Amarela">Faixa Amarela</option>
              <option value="Faixa Laranja">Faixa Laranja</option>
              <option value="Faixa Verde">Faixa Verde</option>
              <option value="Faixa Azul">Faixa Azul</option>
              <option value="Faixa Roxa">Faixa Roxa</option>
              <option value="Faixa Marrom">Faixa Marrom</option>
              <option value="Faixa Preta">Faixa Preta</option>
              <option value="Faixa Coral">Faixa Coral</option>
              <option value="Faixa Vermelha">Faixa Vermelha</option>
            </select>
          </div>

          <div className="form-group">
            <label htmlFor="ingresso">Ingresso:</label>
            <select
              id="ingresso"
              name="ingresso"
              value={formData.ingresso}
              onChange={handleInputChange}
            >
              <option value="selecione">Selecione</option>
              {ingressos.map((ticket, index) => (
                <option key={index} value={ticket.trim()}>
                  {ticket.trim()}
                </option>
              ))}
            </select>
          </div>

          <div className="form-group">
            <label className="need" htmlFor="concordouTermos">
              Eu concordo com os termos e condições
              <input
                className="checkbox"
                type="checkbox"
                id="concordouTermos"
                name="concordouTermos"
                checked={formData.concordouTermos}
                onChange={handleInputChange}
              />
            </label>
            <a
              href="/"
              className="termos-a"
              onClick={(e) => {
                e.preventDefault();
                setShowTermos(true);
              }}
            >
              Termos e Condições
            </a>
          </div>
          {showTermos && (
            <TermosCondicoesServico onClose={() => setShowTermos(false)} />
          )}

          <div className="criar-btn">
            <button type="submit">{data.tipo === 'casada' ? <>Confirmar dados</> : <>Confirmar inscrição</>}</button>
          </div>
        </form>
      )}
    </div>
  );
};

export default ConfirmarDados;
