import React, { useState, useEffect } from "react";
import QRCode from "qrcode.react";
import CopyToClipboard from "react-copy-to-clipboard";
import Carregando from '../../utils/Carregando';

const Pagamento = ({ data, onNext, onBack, formData, subscriptionId }) => {
  const [copied, setCopied] = useState(false);
  const [paymentData, setPaymentData] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  const extractValueFromString = (inputString) => {
    const regex = /R\$(\d+)/; 
    const match = inputString.match(regex);

    if (match && match[1]) {
      return match[1]; 
    }

    return null; 
  }

  useEffect(() => {

    if(subscriptionId){
const inputString = formData.ingresso;
const extractedValue = parseInt((extractValueFromString(inputString)),10);
const formattedValue = extractedValue * 100;

    const url = "https://jacquesgomes.com.br/FightEventsBackend/ingressos/" + subscriptionId;

    const requestBody = {
      reference_id: subscriptionId,
      customer: {
        name: formData.nome,
        email: formData.email,
        tax_id: formData.cpf,
        phones: [
          {
            country: "55",
            area: "11",
            number: "999999999",
            type: "MOBILE",
          },
        ],
      },
      items: [
        {
          name: formData.ingresso,
          quantity: 1,
          unit_amount: formattedValue,
        },
      ],
      qr_codes: [
        {
          amount: {
            value: formattedValue,
          },
          expiration_date: "2024-12-29T20:15:59-03:00",
        },
      ],
      notification_urls: ["https://jacquesgomes.com.br/FightEventsBackend/ingressos"],
    };

    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    })
      .then((response) => response.json())
      .then((data) => {
        setPaymentData(data.qr_codes[0].text);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setError('Algum erro aconteceu, cheque se seu CPF está correto ou entre em contato conosco.');
        setLoading(false);
      });
    }
    }, [data, formData, subscriptionId]);

  return (
    <div className="pagamento">
      <h3>Pagamento da inscrição</h3>

      <p className="pagamento-copy">
        Siga as instruções abaixo para efetuar o pagamento:
      </p>
      <ol>
        <li>Abra o aplicativo do seu banco ou instituição financeira.</li>
        <li>Selecione a opção de pagamento Pix.</li>
        <li>Escaneie o QR Code abaixo ou copie o código.</li>
        <li>Cole o código no campo de pagamento.</li>
        <li>Confirme o pagamento.</li>
      </ol>
      <div className="pagamento-flex">
      {(!loading) && (!error) && (
      <>
         <div className="qrcode-container">
          <QRCode value={paymentData} size={128} />
        </div>

        <div className="copy-code-container">
          <p>Código Pix:</p>
          <input type="text" value={paymentData} readOnly />
          {copied && <div>Copiado!</div>}
        </div>


      <CopyToClipboard text={paymentData} onCopy={() => setCopied(true)}>
        
        <button>Copiar código</button>
      </CopyToClipboard>
        </>
        )}

        {error && <p style={{color: 'red'}}>{error}</p>}

        {loading && (
<Carregando />
        )}
        </div>

    </div>
  );
};

export default Pagamento;
