import React, { useState } from "react";
import HeaderImg from "../../assets/imgs/caveira1.png";
import "./header.css";
import { Link } from "react-router-dom";
import { useAuth } from "../../services/context/AuthContext";
import MenuBars from "../../assets/imgs/menu-bars.svg";
import Close from "../../assets/imgs/close.svg";
import Menu from './Menu';

export function Header() {
  const { user, logout } = useAuth();

  const main = "/";
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const [isOpen, setIsOpen] = useState(false);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="header">
      <header className="header__container">

        <div className="header__img">

          <Link to={main}>
            <img src={HeaderImg} alt="header"></img>
          </Link>
        </div>

        <div className="menu-container-new">
          <section
            className={`sub__header ${!menuOpen ? "menu-btn" : "menu-close"}`}
          >
            <Link to={main}>Eventos</Link>

            <Link to={`${main}checagem/`}>Checagem</Link>
            <Link to={`${main}chaveamento/`}>Chaveamento</Link>
            <Link to={`${main}contato/`}>Contato</Link>
          </section>

          <div className="header__content">
            <div>
              {user ? (
                <div className="login-btn-flex">
                  <Link className="login__btn" to={`${main}perfil/`}>
                    MINHA CONTA
                  </Link>
                </div>
              ) : (
                <div className="login-btn-flex">
                  <Link className="login__btn" to={`${main}login/`}>
                    LOGIN
                  </Link>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className='header-btns-container'>
          <button className='menu-toggle' onClick={toggleSidebar}>
            <img src={MenuBars} alt='Menu Bars' />
          </button>
        </div>


      </header>
      <Menu
      user={user}
        toggleSidebar={toggleSidebar}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />

    </div>
  );
}

export default Header;
