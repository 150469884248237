

export async function deleteSubscription(id) {
    try {
      const response = await fetch(`https://jacquesgomes.com.br/FightEventsBackend/inscricoes/${id}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
      });
  
      if (response.ok) {
        return true;
      }
  
      throw new Error('Erro ao excluir inscrição');
    } catch (error) {
      console.error(error);
      return false;
    }
  }
  

  export async function checkSubscription(fighterId, eventId) {
    try {
      const requestData = {
        fighterId: fighterId,
        eventId: eventId,
      };

      console.log(requestData);

      const response = await fetch(`https://jacquesgomes.com.br/FightEventsBackend/inscricoes/check`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestData),
        
        
      });
  
      if (response.ok) {
        const data = await response.text();

        if(data === '1'){
          return 1;
        } else{
          return 0;
        }

      }
  
      throw new Error('Erro ao excluir inscrição');
    } catch (error) {
      console.error(error);
      return false;
    }
  }
  