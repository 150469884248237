// Menu.js
import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import './Menu.css';
import Close from '../../assets/imgs/close.svg';

const Menu = ({ toggleSidebar, isOpen, setIsOpen, user }) => {
  const sidebarRef = useRef(null);
  const navigate = useNavigate();

  const manualRouting = (address) => {
    navigate(address);
    toggleSidebar();
  };

  const closeSidebar = (event) => {
    if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', closeSidebar);
    return () => {
      document.removeEventListener('mousedown', closeSidebar);
    };
  });

  const main = "/";

  return (
    <>
      <CSSTransition in={isOpen} timeout={500} classNames='fade' unmountOnExit>
        <div>
          <div className='sidebar' ref={sidebarRef}>
            <button
              className='menu-toggle close-menu-btn'
              onClick={toggleSidebar}
            >
              <img src={Close} alt='Close' />
            </button>

            <div className='menu-btns'>

              {user ? (
                <button onClick={() => manualRouting(main + 'perfil')} alt='home'>
                  Minha Conta
                </button>
              ) : (


                <button onClick={() => manualRouting(main + 'login')} alt='home'>
                  Login
                </button>
              )}

              <button onClick={() => manualRouting(main)} alt='home'>
                Eventos
              </button>

              <button onClick={() => manualRouting(main + 'checagem')} alt='home'>
                Checagem
              </button>

              <button onClick={() => manualRouting(main + 'chaveamento')} alt='home'>
                Chaveamento
              </button>

              <button onClick={() => manualRouting(main + 'contato')} alt='home'>
                Contato
              </button>

            </div>
          </div>
          <div className='curtain'></div>
        </div>
      </CSSTransition>
    </>
  );
};

export default Menu;


