import React, { useState, useEffect } from "react";
import "./perfil.css";
import { useAuth } from "../../services/context/AuthContext";
import { useNavigate, Link } from "react-router-dom";
import ConsultarInscricoes from "./InscricoesPerfil";
import AlterarDados from "./AlterarDados";
import MeusAtletas from "../meus-atletas/MeusAtletas";

export function Perfil() {
  const [userData, setUserData] = useState(false);
  const [maisInfo, setMaisInfo] = useState(false);
  const [alterarDados, setAlterarDados] = useState(false);
  const [activeTab, setActiveTab] = useState("dados");
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();
  const tipoUsuario = localStorage.getItem("tipoUsuario");
  const { user, logout } = useAuth();


  const main = "/";

  const toggleAlterarDados = () => {
    setAlterarDados(!alterarDados);
  };

  const toggleAddress = () => {
    setMaisInfo(!maisInfo);
  };
  useEffect(() => {
    const userId = localStorage.getItem("user");

    const fetchData = async () => {
      try {
        if (user) {
          const response = await fetch(`https://jacquesgomes.com.br/FightEventsBackend/atletas/${userId}`);
          if (!response.ok) {
            throw new Error("Failed to fetch user data");
          }
          const data = await response.json();
          setUserData(data);
        } else {
          alert("Por favor, realize o login para acessar seu perfil");
          navigate("/login");
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      } finally {
        setLoading(false); // Set loading to false after the fetch, whether it was successful or not
      }
    };

    const delayFetch = setTimeout(fetchData, 1000); // Add a delay of 1000 milliseconds (1 second)

    return () => clearTimeout(delayFetch); // Clear the timeout if the component unmounts or the dependency changes
  }, [user, navigate]);

  return (
    <div>
      {userData ? (
        <div className="perfil">
          <div className="user-container">
            <div className="user-side">
              <div className="user-btns">
                <button
                  id="dados"
                  className={activeTab === "dados" ? "active" : ""}
                  onClick={() => setActiveTab("dados")}
                >
                  Meus dados
                </button>

                <button
                  id="meusAtletas"
                  className={activeTab === "meusAtletas" ? "active" : ""}
                  onClick={() => setActiveTab("meusAtletas")}
                >
                  Meus atletas
                </button>
              </div>
            </div>

            {activeTab === "dados" && (
              <div className="user-info">
                <div className="info-main  user-info-sub">
                  <div>
                    <span className="edit-block">
                      <h3>Informações pessoais</h3>
                    </span>
                    <div className="perfil-teste">
                      <div>
                        <p>
                          <strong>Nome Completo:</strong> {userData.nomeCompleto}
                        </p>
                        <p>
                          <strong>Email:</strong> {userData.email}
                        </p>
                        <p>
                          <strong>Perfil:</strong> {userData.perfil}
                        </p>
                        <p>
                          <strong>Celular:</strong> {userData.celular}{" "}
                        </p>
                        <p>
                          <strong>Data de nascimento:</strong>{" "}
                          {userData.dataDeNascimento}
                        </p>
                      </div>

                      {tipoUsuario !== "responsavel" && (
                        <div className="main-fighter-info">
                          {/* <p>
                            <strong>Peso:</strong> {userData.peso} kg
                          </p>
                          <p>
                            <strong>Equipe:</strong> {userData.equipe}
                          </p> */}
                          <p>
                            <strong>Professor:</strong> {userData.professor}
                          </p>
                          <p>
                            <strong>Graduação:</strong> {userData.graduacao}
                          </p>
                          <p>
                            <strong>Instagram:</strong> {userData.instagram}
                          </p>
                        </div>
                      )}
                      {maisInfo && (
                        <>
                          <p>
                            <strong>CPF:</strong> {userData.cpf}
                          </p>
                          <p>
                            <strong>País:</strong> {userData.pais}
                          </p>
                          <p>
                            <strong>Estado:</strong> {userData.estado}
                          </p>
                          <p>
                            <strong>Cidade:</strong> {userData.cidade}
                          </p>
                          <p>
                            <strong>Bairro:</strong> {userData.bairro}
                          </p>
                          <p>
                            <strong>Endereço:</strong> {userData.endereco}
                          </p>
                          <p>
                            <strong>Número:</strong> {userData.numero}
                          </p>
                          <p>
                            <strong>CEP:</strong> {userData.cep}
                          </p>
                        </>
                      )}
                    </div>

                    {alterarDados && (
                      <span>
                        <AlterarDados userData={userData} setUserData={setUserData} fecharForm={toggleAlterarDados}/>
                      </span>
                    )}
                    <span className="meus-atletas-btns">
                      <button className="mais-info-btn" onClick={toggleAddress}>
                        {maisInfo ? "Ver menos" : "Ver mais"}
                      </button>

                      <button
                        className="mais-info-btn"
                        onClick={toggleAlterarDados}
                      >
                        {alterarDados ? "Cancelar alterações" : "Alterar dados"}
                      </button>
                    </span>
                  </div>
                </div>

                {tipoUsuario !== "responsavel" && userData && (
                  <ConsultarInscricoes userCPF={userData.cpf} professor={userData.professor} />
                )}
              </div>
            )}

            {activeTab === "meusAtletas" && (
              <MeusAtletas email={userData.email} />
            )}

<Link className="login__btn sair-btn" onClick={logout} to={`${main}`}>
                  SAIR
                </Link>

          </div>
        </div>
      ) : (
        <div>
          <p>Carregando informações do usuário...</p>
        </div>
      )}
    </div>
  );
}

export default Perfil;
