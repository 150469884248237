import React, { useState } from 'react';

const SubscriptionLookup = () => {
  const [email, setEmail] = useState('');
  const [cpf, setCpf] = useState('');
  const [erro, setErro] = useState('');
  const [subscriptions, setSubscriptions] = useState([]);

  const handleCPFInputChange = (e) => {

    const inputValue = e.target.value.replace(/[^0-9]/g, '');
  
      if (inputValue.length <= 11) {
        setCpf(inputValue);
      }
  
  };

  const handleConsulta = async (e) => {
    e.preventDefault();

    if (!cpf) {
      alert('Por favor, informe um Email ou CPF');
      return;
    }

    try {
      let endpoint = 'https://jacquesgomes.com.br/FightEventsBackend';

      endpoint += `/checagem/cpf/${cpf}`;

      const response = await fetch(endpoint);
      const data = await response.json();
      if (response.status === 200) {
        setSubscriptions(data);
        setErro('');
      } else {
        setSubscriptions([]);
        
        if(response.status === 404){
          setErro(data.error || 'Nenhuma inscrição encontrada, verifique se o CPF está correto.');
      }
    }

    } catch (error) {

      console.error('Erro:', error);
      setErro(error);
    }
  };

  return (
    <div className="login-content">
      <form className="login-form checagem-table" onSubmit={handleConsulta}>
        <h3 className='consulta-ind-title'>CONSULTAR INSCRIÇÕES POR ATLETA</h3>

        <div className='consulta-flex'>

          <div className='consulta-flex-child'>
            <div className="form-group">
              <label htmlFor="cpf">CPF</label>
              <input
                type="text"
                id="cpf"
                value={cpf}
                onChange={(e) => handleCPFInputChange(e)}
                placeholder='Somente números, ex: 91212345629'
              />
            </div>

            {erro && <p style={{color: 'red'}}>{erro}</p>}

            <button type="submit" className="login-btn">
              Consultar por CPF
            </button>

          </div>

        </div>
      </form>

      {subscriptions.length > 0 && (

        <div className='table table-ind'>
          <table>
            <thead>
              <tr>
                <th className="bloquinho-medio">Evento</th>

                <th className="bloco-categoria">Categoria</th>

                <th className='bloquinho-consulta'>Status</th>
              </tr>
            </thead>
            <tbody>
            {subscriptions.map((subscription, index) => (
                  <tr key={index}>
                    <td className="bloquinho-medio">{subscription.tituloEvento}, inscrição: {subscription.id}</td>

                    <td className="bloco-categoria">{subscription.faixa}, {subscription.peso}, absoluto: {subscription.absoluto}
                    </td>

                    <td className='bloquinho-consulta'>{subscription.status}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        )}

      
    </div>
  );
};

export default SubscriptionLookup;
