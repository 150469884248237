const formatarHorarios = (horarios) => {
    const horariosArray = horarios.split(', ');
  
    const horariosFiltrados = horariosArray
      .filter((horario) => {
        const [horarioFormatado, numero] = horario.split(' - ');
        return parseInt(numero, 10) > 0;
      })
      .map((horario) => {
        const [horarioFormatado] = horario.split(' - ');
        return horarioFormatado;
      });
  
      const horariosSemParenteses = horariosFiltrados.map(horario => horario.substring(1));
    return horariosSemParenteses;
  };
  
  export { formatarHorarios };