import React, { useState } from 'react';
import './Esqueci.css';

const EsqueciSenha = () => {
  const [email, setEmail] = useState('');
  const [mensagem, setMensagem] = useState('');
  const [erro, setErro] = useState('');

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const resposta = await fetch('https://jacquesgomes.com.br/FightEventsBackend/enviar-email-resetar-senha', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      if (resposta.ok) {
        setMensagem('Um e-mail de redefinição de senha foi enviado para o seu endereço de e-mail.');
        setErro('');
      } else {
        setMensagem('');
        setErro('Ocorreu um erro ao enviar o e-mail. Por favor, tente novamente.');
      }
    } catch (error) {
      setMensagem('');
      setErro('Ocorreu um erro ao enviar o e-mail. Por favor, tente novamente.');
    }
  };

  return (
    <div className='esqueci-a-senha-container'>
      <h3>Esqueci a Senha</h3>
      <form onSubmit={handleSubmit}>
        <label>
          Email<br></br>
          <input type="email" value={email} onChange={handleEmailChange} required />
        </label>
        <button type="submit">Enviar</button>
      </form>
      {mensagem && <p>{mensagem}</p>}
      {erro && <p style={{ color: 'red' }}>{erro}</p>}
      <p>
        Lembre-se de verificar a pasta de spam ou lixo eletrônico se você não receber o e-mail
        dentro de alguns minutos.
      </p>
    </div>
  );
};

export default EsqueciSenha;
