import React, { useState, useEffect } from 'react';
import {  checkSubscription } from '../../../services/api/api';

const SelectAthlete = ({ data, onNext, onAthleteSelect }) => {

  const [opcaoSelecionada, setOpcaoSelecionada] = useState('inscricaoParaMim');

  const [athleteOptions, setAthleteOptions] = useState([]);

  const [meuAtleta, setMeuAtleta] = useState('');

  const atletaId = localStorage.getItem('user');
  const tipoUsuario = localStorage.getItem('tipoUsuario');

  const [fetchError, setFetchError] = useState(false);

  const handleOpcaoChange = (e) => {
    setOpcaoSelecionada(e.target.value);
  };

  const handleSelectionChange = (e) => {
    setMeuAtleta(e.target.value);
  };

  const handleAthleteSelect = async (e) => {
    e.preventDefault();
  
    try {
      if (opcaoSelecionada === 'inscricaoParaMim') {

        if(data.tipo === 'casada'){
          onAthleteSelect(atletaId);
        } else{
        const test = await checkSubscription(atletaId, data.eventId);
  
        if (test === 1) {
          alert("Já existe uma inscrição nesse evento com esse CPF.");
        } else {
          onAthleteSelect(atletaId);
        }
      }

      } else {

        if (!meuAtleta) {
          alert("Selecione um atleta");
        } else {
          if(data.tipo === 'casada'){
            onAthleteSelect(meuAtleta);
          } else{
          const test = await checkSubscription(meuAtleta, data.eventId);
          if (test === 1) {
            alert("Já existe uma inscrição nesse evento com esse CPF.");
          } else {
            onAthleteSelect(meuAtleta);
          }
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    
    const apiUrl = `https://jacquesgomes.com.br/FightEventsBackend/meus-atletas/` + atletaId;

    fetch(apiUrl, {
    method: 'GET',
    mode: 'cors',
    })
    .then((response) => {
        if (!response.ok) {
        throw new Error('Network response was not ok');
        }
        return response.json();
    })
    .then((athletes) => {
        setAthleteOptions(athletes);
        })
    .catch((error) => {
        console.error('Error fetching additional data:', error);
        setFetchError(true);
        
    });
    
  }, [atletaId, fetchError]);

  return (
    <div className="athlete-selection">
      <h3 className="title-modal">{data.title}</h3>

      <form onSubmit={handleAthleteSelect} className="modal-responsavel">
        <div className='main-resp'>
          
          <label className="label-title" htmlFor="athleteSelect">Deseja inscrever quem?</label>
          {tipoUsuario === 'atleta' && (
            <div className="radio-label">
              <div>
                <label>
                  Eu mesmo
                </label>
                <input
                  type="radio"
                  name="opcao"
                  value="inscricaoParaMim"
                  checked={opcaoSelecionada === 'inscricaoParaMim'}
                  onChange={handleOpcaoChange}
                />
              </div>
              <div>
                <label className="radio-label">
                  Um dos meus atletas
                </label>
                <input
                  type="radio"
                  name="opcao"
                  value="inscricaoParaAtleta"
                  checked={opcaoSelecionada === 'inscricaoParaAtleta'}
                  onChange={handleOpcaoChange}
                />
              </div>
            </div>
          )}
          {(opcaoSelecionada === 'inscricaoParaAtleta' || tipoUsuario === 'responsavel') && (
            <div>
              <select
                id="athleteSelect"
                name="athleteSelect"
                value={meuAtleta}
                onChange={handleSelectionChange}
              >
                <option value="">Selecione um atleta</option>
                {athleteOptions.map((athlete) => (
                  <option key={athlete.id} value={athlete.id}>
                    {athlete.nomeCompleto}
                  </option>
                ))}
              </select>
            </div>
          )}
        </div>

        <button type="submit">Próximo</button>
      </form>
    </div>
  );
};

export default SelectAthlete;
