import React from "react";
import './politicas.css';

export function Privacidade() {
  return (
    <div className="politicas">
      <div>
        <h1>Política de Cookies</h1>

        <h3>Introdução</h3>
        <p>
          Esta Política de Cookies descreve como o site JiuJitsu Caveira utiliza
          cookies e tecnologias similares para melhorar a experiência do usuário
          em nosso site. Ao continuar a usar nosso site, você concorda com o uso
          de cookies de acordo com esta política.
        </p>

        <h3>O que são Cookies?</h3>
        <p>
          Cookies são pequenos arquivos de texto que são armazenados no seu
          navegador quando você visita um site. Eles são amplamente usados para
          que um site funcione corretamente, melhore a sua experiência de
          navegação, ou forneça informações para os proprietários do site.
        </p>

        <h3>Como Usamos Cookies</h3>
        <p>Utilizamos cookies para diversos fins, incluindo:</p>
        <ul>
          <li>Melhorar a funcionalidade do site;</li>
          <li>Personalizar sua experiência;</li>
          <li>Analisar como os usuários interagem com nosso site;</li>
          <li>Fornecer publicidade direcionada.</li>
        </ul>

        <h3>Tipos de Cookies</h3>
        <p>Nosso site utiliza os seguintes tipos de cookies:</p>
        <ul>
          <li>
            Cookies essenciais: São necessários para o funcionamento básico do
            site e não podem ser desativados.
          </li>
          <li>
            Cookies de desempenho: Coletam informações sobre como os visitantes
            usam o site, como páginas visitadas e erros encontrados. Esses
            cookies não coletam informações que identificam o visitante.
          </li>
          <li>
            Cookies de funcionalidade: Permitem que o site se lembre das
            escolhas do visitante, como o idioma ou região.
          </li>
          <li>
            Cookies de publicidade: São usados para fornecer anúncios mais
            relevantes para o visitante.
          </li>
        </ul>

        <h3>Gerenciando Cookies</h3>
        <p>
          Você pode controlar e/ou excluir os cookies do seu navegador. Consulte
          as configurações do seu navegador para fazer isso. Observe que a
          desativação de cookies pode afetar a funcionalidade do site.
        </p>

        <h3>Contatos</h3>
        <p>
          Se você tiver alguma dúvida sobre nossa Política de Cookies, entre em
          contato conosco através do e-mail caveiraeventos23@gmail.com 
        </p>

        <p>Esta política foi atualizada em 23/09/2023.</p>
      </div>
    </div>
  );
}

export default Privacidade;
