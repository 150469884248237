import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import "./eventDetails.css";
import Modal from "./inscricoes/InscricaoModal";
import Edital from "./Edital";
import eventImg from '../../assets/imgs/arena2.webp';

const EventDetail = () => {
  const [event, setEvent] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [edital, setEdital] = useState(false);


  const toggleEdital = () =>{
    setEdital(!edital);
  };

  const { id } = useParams();

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const isTokenPresente = () => {
    const token = localStorage.getItem("token");

    if (token) {
      openModal(modalData);
    } else {
      alert("Faça o login primeiro");
    }
  };

  useEffect(() => {
    if (id) {
      const apiUrl = `https://jacquesgomes.com.br/FightEventsBackend/eventos/${id}`;

      fetch(apiUrl, {
        method: "GET",
        mode: "cors",
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((data) => {
          const modalData = {
            title: data.titulo,
            ingressos: data.valoresInscricoes,
            dataDoEvento: data.data,
            eventId: data.id,
            local: data.local,
            tipo: data.tipo,
            horarios: data.horarios,
          };

          setModalData(modalData);
          setEvent(data);
          setIsLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching event:", error);
          setError(error.message);
          setIsLoading(false);
          
        });
    }
  }, [id]);

  if (isLoading) {
    return <div>Carregando...</div>;
  }

  if (!event) {
    return <div>Evento não encontrado.</div>;
  }

  return (
    <div className="event-container">
      <div className="event-detail">

        <div className="event-header">

          <div className="event-img">
        <img src={eventImg} alt='event-img'></img></div>


        <div>
          <h3>{event.titulo} </h3>

          <div className="event-dates-header">
          <div>
              <h4>Data:</h4>
              <p>{event.data}</p>
            </div>
            <div>
              <h4>Inscrições até:</h4>
              <p>{event.dataInscricoes}</p>
            </div>
          </div>
            <div className="event-btns">
              <button onClick={isTokenPresente}>Inscreva-se</button>
              <button className="event-last-btn" onClick={toggleEdital}>{edital ? 'Fechar Edital' : 'Edital'}</button>
            </div>
        </div>
        </div>

        {edital && <Edital evento={event} handleVoltar={toggleEdital}/>}

        <Modal isOpen={isModalOpen} data={modalData} onClose={closeModal} />

        {!edital && <>
        <div className="event-dates">
          <div>
            <div>
              <h4>Data:</h4>
              <p>{event.data}</p>
            </div>
            <div>
              <h4>Inscrições até:</h4>
              <p>{event.dataInscricoes}</p>
            </div>
          </div>

          <div>

          <div>
            <h4>Data de entrega das chaves:</h4>
            <p>{event.dataChaves}</p>
          </div>
            <div>
              <h4>Checagem:</h4>
              <p>{event.dataChecagem}</p>
            </div>
          </div>
        </div>

        <div>
          <h4>Sobre o evento:</h4>
          <p>{event.sobreEvento}</p>
        </div>
        <div>
          <h4>Local:</h4>
          <p>{event.local}</p>
        </div>
        <div>
          <h4>Espaço:</h4>
          <p>{event.espaco}</p>
        </div>
        {/* <div>
          <h4>Endereço:</h4>
          <p>{event.endereco}</p>
        </div> */}
        <div>
          <h4>Endereço no Google Maps:</h4>
          <a className="map-link" aria-label="Clique aqui para ver o endereço no Google Maps" href={`${event.linkMapas}`} style={{textDecoration: 'underline'}}>
            Clique aqui para ver o endereço no Google Maps.
          </a>
        </div>
        <div>
          <h4>Valores das inscrições:</h4>
          <ul>{event.valoresInscricoes}</ul>
        </div>
        <div>
          <h4>Informações sobre os organizadores:</h4>
          <ul>{event.sobreOrganizacao}</ul>
        </div>
        <div>
          <h4>Premiações:</h4>
          <ul>{event.premiacao}</ul>
        </div>
        </>}

      </div> 
    </div>

  );
};

export default EventDetail;
