import React, { useState, useEffect } from "react";

const EditProfile = ({ userData, setUserData, fecharForm }) => {
  const atletaId = localStorage.getItem("user");

  const [formData, setFormData] = useState({
    nomeCompleto: "",
    email: "",
    idade: "",
    cpf: "",
    celular: "",
    pais: "",
    estado: "",
    cidade: "",
    bairro: "",
    endereco: "",
    numero: "",
    cep: "",
    peso: "",
    equipe: "",
    professor: "",
    graduacao: "",
    instagram: "",
    referencia: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  useEffect(() => {
    if (userData) {
      setFormData({
        ...userData,
      });
    }
  }, [userData]);

  const handleSubmit = (e) => {
    e.preventDefault();

    fetch("https://jacquesgomes.com.br/FightEventsBackend/atleta/" + atletaId, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to update user data");
        }

        setUserData(formData);
        fecharForm();

        alert("Dados atualizados com sucesso!");
      })
      .catch((error) => {
        console.error("Error updating user data:", error);

        alert("Falha em atualizar os dados do usuário.");
      });
  };

  return (
    <div>
      {userData ? (
        <form className="alterar-form-main" onSubmit={handleSubmit}>
          <div className="right-main">
            <h3>Dados pessoais</h3>

            <div className="form-group">
              <label>Nome:</label>
              <input
                type="text"
                name="nomeCompleto"
                value={formData.nomeCompleto}
                onChange={handleInputChange}
              />
            </div>

            {/* <div className="form-group">
              <label>Email:</label>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
              />
            </div> */}

            {/* <div className="form-group">
              <label>
                CPF:
              </label>
              <input
                type="text"
                name="cpf"
                value={ formData.cpf}
                onChange={handleInputChange}
              />
            </div> */}

            <div className="form-group">
              <label>Celular:</label>
              <input
                type="text"
                name="celular"
                value={formData.celular}
                onChange={handleInputChange}
              />
            </div>

            <div className="form-group">
              <label>Perfil:</label>
              <input
                type="text"
                name="perfil"
                value={formData.perfil}
                onChange={handleInputChange}
                readOnly={true}
              />
            </div>
          </div>
          
{formData.perfil !== 'responsavel' && 
        <div className="right-main">
          <h3>Dados do esporte</h3>

          {/* <div className="form-group">
            <label>
              Peso (kg):
            </label>
            <input
              type="number"
              name="peso"
              value={ formData.peso}
              onChange={handleInputChange}
            />
          </div>

          <div className="form-group">
            <label>
              Equipe:
            </label>
            <input
              type="text"
              name="equipe"
              value={ formData.equipe}
              onChange={handleInputChange}
            />
          </div> */}

          <div className="form-group">
            <label>Professor:</label>
            <input
              type="text"
              name="professor"
              value={formData.professor}
              onChange={handleInputChange}
            />
          </div>

          <div className="form-group">
            <label htmlFor="faixa">Faixa*</label>
            <select
              id="faixa"
              value={formData.graduacao}
              onChange={handleInputChange}
            >
              <option value="">Selecione</option>
              <option value="Faixa Branca">Faixa Branca</option>
              <option value="Faixa Cinza">Faixa Cinza</option>
              <option value="Faixa Amarela">Faixa Amarela</option>
              <option value="Faixa Laranja">Faixa Laranja</option>
              <option value="Faixa Verde">Faixa Verde</option>
              <option value="Faixa Azul">Faixa Azul</option>
              <option value="Faixa Roxa">Faixa Roxa</option>
              <option value="Faixa Marrom">Faixa Marrom</option>
              <option value="Faixa Preta">Faixa Preta</option>
              <option value="Faixa Coral">Faixa Coral</option>
              <option value="Faixa Vermelha">Faixa Vermelha</option>
            </select>
          </div>
          <div className="form-group">
              <label>Instagram:</label>
              <input
                type="text"
                name="instagram"
                value={formData.instagram}
                onChange={handleInputChange}
              />
            </div>
        </div>}


          <div className="endereco-main-wrapper">
            <h3>Endereço</h3>
            <div className="endereco-main">
              <div className="endereco-main-sub">
                <div className="form-group">
                  <label>País:</label>
                  <input
                    type="text"
                    name="pais"
                    value={formData.pais}
                    onChange={handleInputChange}
                  />
                </div>

                <div className="form-group">
                  <label>Estado:</label>
                  <input
                    type="text"
                    name="estado"
                    value={formData.estado}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="form-group">
                  <label>Cidade:</label>
                  <input
                    type="text"
                    name="cidade"
                    value={formData.cidade}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="form-group">
                  <label>Bairro:</label>
                  <input
                    type="text"
                    name="bairro"
                    value={formData.bairro}
                    onChange={handleInputChange}
                  />
                </div>
              </div>

              <div className="endereco-main-sub">
                <div className="form-group">
                  <label>Endereço:</label>

                  <input
                    type="text"
                    name="endereco"
                    value={formData.endereco}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="form-group">
                  <label>Número:</label>
                  <input
                    type="text"
                    name="numero"
                    value={formData.numero}
                    onChange={handleInputChange}
                  />
                </div>

                <div className="form-group">
                  <label>CEP:</label>
                  <input
                    type="text"
                    name="cep"
                    value={formData.cep}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="criar-btn">
            <button type="submit">Salvar Alterações</button>
          </div>
        </form>
      ) : (
        <p>Loading user data...</p>
      )}
    </div>
  );
};

export default EditProfile;
