import React, { useState } from 'react';
import './inscricaoModal.css';
import './inscricao.css';
import SelectAthlete from './SelecionarAtleta';
import ConfirmarDados from './ConfirmarDados';
import LutaCasada from './LutaCasada';
import Pagamento from './Pagamento';

const Modal = ({ isOpen, onClose, data }) => {

  const resetInfo = () =>{
    setAthleteSelection(null);
    setFightSelection(null);
    setCurrentStep(1);
    setFormData({});
   }

  const [currentStep, setCurrentStep] = useState(1);

  const [formData, setFormData] = useState({});
  const [athleteSelection, setAthleteSelection] = useState(null);
  const [fightSelection, setFightSelection] = useState(null);
  const [lutadorDesafiado, setLutadorDesafiado] = useState(null);
  const[fightTime, setFightTime] = useState(null);
  const[subscriptionId, setSubscriptionId] = useState(null);

  const handleAthleteSelect = (athleteId) => {
    setAthleteSelection(athleteId);
    setCurrentStep(2);
  };

  const handleNextStep = () => {
    setCurrentStep(currentStep + 1);
  };

  const handleBackStep = () => {
    setCurrentStep(currentStep - 1);
  };

  const handleFormDataChange = (newFormData) => {
    setFormData({ ...formData, ...newFormData });
  
    if (data.tipo === 'casada') {
      setCurrentStep(3);
    } else {
      createSubmission(newFormData);
      setCurrentStep(4);
    }
  };
  
  const handleSelectFight = (luta, lutador, type) => {

    if (type === 'fight') {
      setFightSelection(luta);
      setLutadorDesafiado(lutador);
    } else if (type === 'time') {
      setFightTime(luta);
    }

    createSubmission(luta, lutador, type);
    setCurrentStep(4);
  };
  
  const createSubmission = async (newData, lutador, type) => {

    var oponenteData = null;
    var horarioLuta = '';
    var oponenteId = '';
    var flag = 1;
    var escolheuIniciar = 'não';

    if (type === 'fight') {
      oponenteData = newData;
      oponenteId = lutador;
      flag = 2;
    } else if (type === 'time' && newData !== null) {
      horarioLuta = newData;
      flag = 2;
    } else {
      escolheuIniciar = 'sim';
      flag = 2;
    }

    const formRealData = {
      eventId: data.eventId,
      tipo: data.tipo,
      localDoEvento: data.local,
      dataDoEvento: data.dataDoEvento,
      tituloEvento: data.title,
      fighterId: athleteSelection,
      valorIngresso: flag === 1 ? newData.ingresso : formData.ingresso,
      nome: flag === 1 ? newData.nome : formData.nome,
      faixa: flag === 1 ? newData.graduacao : formData.graduacao,
      idade: flag === 1 ? newData.idade : formData.idade,
      academia: flag === 1 ? newData.equipe : formData.equipe,
      email: flag === 1 ? newData.email : formData.email,
      peso: flag === 1 ? newData.categoria : formData.categoria,
      sexo: flag === 1 ? newData.sexo : formData.sexo,
      cpf: flag === 1 ? newData.cpf : formData.cpf,
      oponente: oponenteId,
      // Verifica se oponenteData não é nulo ou vazio antes de incluir no objeto
      ...(oponenteData !== null && { inscricaoOponente: oponenteData }),
      escolheuIniciar,
      instagram: formData.instagram,

    horarioLuta,
      absoluto: 'não',
    };

    try {
      const response = await fetch('https://jacquesgomes.com.br/FightEventsBackend/inscricoes', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formRealData),
      });
  
      if (response.ok) {
        const responseData = await response.json();
        const createdId = responseData.id;
        setSubscriptionId(createdId);
        
      } else {
        console.error('Error sending form data');
      }
    } catch (error) {
      console.error('Error sending form data:', error);
    }
  
    // onClose();
  };
  

  return (
    <div className={`modal ${isOpen ? 'open' : ''}`}>
      <div className="modal-content">
        
        <span className="close" onClick={() => { onClose(); resetInfo(); }}>&times;</span>

        {currentStep === 1 && (
          <SelectAthlete
            data={data}
            onNext={handleNextStep}
            onAthleteSelect={handleAthleteSelect}
          />
        )}

        {currentStep === 2 && (
          <ConfirmarDados
            data={data}
            athleteId={athleteSelection}
            onNext={handleNextStep}
            onBack={handleBackStep}
            onFormDataChange={handleFormDataChange}
          />
        )}

        {currentStep === 3 && (
          <LutaCasada
            data={data}
            formData={formData}
            athleteId={athleteSelection}
            onNext={handleNextStep}
            onBack={handleBackStep}
            onSelectFight={handleSelectFight}
          />
        )}

        {currentStep === 4 && (
          <Pagamento
            data={data}
            formData={formData}
            subscriptionId={subscriptionId}
            onNext={handleNextStep}
            onBack={handleBackStep}
            
          />
        )}

      </div>
    </div>
  );
  
};

export default Modal;
