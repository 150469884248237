import React, { useState, useEffect } from "react";
import { formatarHorarios } from "../../utils/formatarHorarios";
import Carregando from "../../utils/Carregando";

const LutaCasada = ({
  data,
  formData,
  athleteId,
  onSelectFight,
}) => {

  const [selectedFight, setSelectedFight] = useState("");
  const [availableFights, setAvailableFights] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedTime, setSelectedTime] = useState("");

  const handleTimeSelection = (event) => {
    setSelectedTime(event.target.value);
  };

  const availableTimes = formatarHorarios(data.horarios);

  useEffect(() => {
    const realFormData = {
      eventId: data.eventId,
      faixa: formData.graduacao,
      sexo: formData.sexo,
      peso: formData.categoria,
      fighterId: parseInt(athleteId, 10),
      ingresso: formData.ingresso,
    };

    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://jacquesgomes.com.br/FightEventsBackend/inscricoes-lutas",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(realFormData),
          }
        );
  
        if (response.ok) {
          const data = await response.json();
          // Define o estado com os resultados filtrados
          setAvailableFights(data);
          setLoading(false);
        } else {
          console.error("Error sending form data");
          setLoading(false);
        }
      } catch (error) {
        console.error("Error sending form data:", error);
        setLoading(false);
      }
    };
  
    fetchData();
    // onClose();
  }, []);

  return (
    <div className="pagamento-container">
      {loading ? (
        <Carregando />
      ) : (
        <>
          <h3 className="title-modal">{data.title}</h3>

          {availableFights.length > 0 && (
            <div className="available-fights">
              <h4>Lutas disponíveis:</h4>
              <div className="fights-container">
                {availableFights.map((fight) => (
                  <div key={fight.id} className="fight-card">
                    <div>
                      <strong>Atleta: {fight.nome}</strong>
                      {fight.horarioLuta ? <p>Horário: {fight.horarioLuta} </p>: <></>}
                      <p>Peso: {fight.peso}</p>
                      <p>Idade: {fight.idade}</p>
                      <p>Graduação: {fight.faixa}</p>
                      <p>Academia: {fight.academia}</p>
                      {fight.instagram ? <p>Instagram: <a href={"https://www.instagram.com/" + fight.instagram} style={{textDecoration: 'underline'}} target="_blank" rel="noreferrer"> {fight.instagram}</a></p> : <></>}

                    </div>

                    <button
                      onClick={() =>
                        onSelectFight(fight.id, fight.fighterId, "fight")
                      }
                    >
                      Desafiar
                    </button>
                  </div>
                ))}
              </div>

              <div className="horarios">
                <p>
                  Caso não queira desafiar nenhum dos atletas listados, você
                  pode iniciar uma nova luta e esperar que alguém te desafie.
                </p>

                <p>
                  <strong>Importante: </strong> seu nome só será listado nas
                  lutas disponíveis após a confirmação do pagamento!{" "}
                </p>

              <button onClick={() => onSelectFight(null, null, "time")}>
                Iniciar nova luta
              </button>
              
              </div>
            </div>
          )}

          {availableFights.length < 1 && (
            <div className="horarios">
              <p>
                Não encontramos nenhum oponente na sua categoria. Inicie uma
                nova luta e espere até que algum oponente te desafie.
              </p>

              <p>
                <strong>Importante: </strong> seu nome só será listado nas lutas
                disponíveis após a confirmação do pagamento!{" "}
              </p>

              <p>Nós te avisaremos por email assim que alguém te desafiar.</p>
              <button onClick={() => onSelectFight(null, null, "time")}>
                Iniciar nova luta
              </button>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default LutaCasada;
