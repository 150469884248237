import React, { useState } from 'react';
import './chaveamento.css';
function Matchup() {

  const [subscriptionsData, setsubscriptionsData] = useState([]);
  const [filtroEvento, setFiltroEvento] = useState(null);
  const [filtroFaixa, setFiltroFaixa] = useState(null);
  const [filtroSexo, setFiltroSexo] = useState(null);
  //const [filtroIdade, setFiltroIdade] = useState(null);
  const [aviso, setAviso] = useState(false);
  const [mostrarLista, setMostrarLista] = useState(false);
  const handleFilterSubmit = () => {

  }
  return (

    <div className="matchup">

      <p>O chaveamento ainda não está disponível.</p>
{/* 
      <div>
      <button className='chaveamento-btn'>
      <a href={process.env.PUBLIC_URL + '/Chaveamento.pdf'} download="Chaveamento.pdf" onClick={console.log(process.env.PUBLIC_URL + '/Chaveamento.pdf')}>GERAR PDF EM BRANCO</a>
      </button>
      </div>

      <div className="checagem">
      <div className="checagem-content">
        <h3>CONSULTAR CHAVEAMENTO POR EVENTO</h3>
        <div className="filtros">
          <div>
            <label htmlFor="filtroEvento">Filtrar por Evento:</label>
            <select
              id="filtroEvento"
              value={filtroEvento}
              onChange={(e) => {
                const selectedValue = e.target.value;
                setFiltroEvento(selectedValue);
                setAviso(true);
              }}
            >
              <option value="selecione">Selecione</option>
              <option value="1">Evento 1</option>
              <option value="2">Evento 2</option>
            </select>
          </div>
          <div>
            <label htmlFor="filtroFaixa">Filtrar por Faixa:</label>
            <select
              id="filtroFaixa"
              value={filtroFaixa}
              onChange={(e) => {
                setFiltroFaixa(e.target.value);
              }}
            >
              <option value="">Todas</option>
              <option value="Faixa Branca">Faixa Branca</option>
              <option value="Faixa Cinza">Faixa Cinza</option>
              <option value="Faixa Amarela">Faixa Amarela</option>
              <option value="Faixa Laranja">Faixa Laranja</option>
              <option value="Faixa Verde">Faixa Verde</option>
              <option value="Faixa Azul">Faixa Azul</option>
              <option value="Faixa Roxa">Faixa Roxa</option>
              <option value="Faixa Marrom">Faixa Marrom</option>
              <option value="Faixa Preta">Faixa Preta</option>
              <option value="Faixa Coral">Faixa Coral</option>
              <option value="Faixa Vermelha">Faixa Vermelha</option>
            </select>
          </div>
          <div>
            <label htmlFor="filtroSexo">Filtrar por Sexo:</label>
            <select
              id="filtroSexo"
              value={filtroSexo}
              onChange={(e) => {
                setFiltroSexo(e.target.value);
              }}
            >
              <option value="">Todos</option>
              <option value="Masculino">Masculino</option>
              <option value="Feminino">Feminino</option>
            </select>
          </div>

          <div>
            <label htmlFor="filtroIdade">Filtrar por Idade:</label>
            <select
              id="filtroIdade"
              value={filtroIdade}
              onChange={(e) => {
                setFiltroIdade(e.target.value);
              }}
            >
              <option value="">Todas</option>
              <option value="Infantil">Infantil 4-15 anos</option>
              <option value="Juvenil">Juvenil 16/17 anos </option>
              <option value="Adulto">Adulto 18/30 anos</option>
              <option value="Máster">Máster 30/40 anos</option>
              <option value="Sênior">Sênior +40 anos</option>
            </select>
          </div> 

        </div>
        <div>
          <button className="checagem-btn" onClick={handleFilterSubmit}>
            Filtrar
          </button>
        </div>
        {!aviso && (
          <div>
            <p>Nenhum evento selecionado, por favor, selecione um evento.</p>
          </div>
        )}

        {mostrarLista && (
          <div className="table">
            {subscriptionsData.length > 0 ? (
              <>
                <h6>{subscriptionsData.length} INSCRIÇÕES ENCONTRADAS.</h6>
                <table>
                  <thead>
                    <tr>
                      <th className="bloquinho-medio">Nome | Inscrição</th>
                      <th className="bloco-categoria">Categoria | Academia</th>
                      <th className="bloquinho-consulta">Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {subscriptionsData.map((subscription, index) => (
                      <tr key={index}>
                        <td className="bloquinho-medio">
                          {subscription.nome}, inscrição: {subscription.id}
                        </td>
                        <td className="bloco-categoria">
                          {subscription.faixa}, idade: {subscription.idade}, {subscription.peso}kg, {subscription.sexo}, {subscription.academia}, absoluto: {subscription.absoluto}
                        </td>
                        <td className="bloquinho-consulta">
                          {subscription.status}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </>
            ) : (
              <p>Nenhuma inscrição encontrada</p>
            )}
          </div>
        )}
      </div>
      </div>
 */}    </div>
  );
}

export default Matchup;
