import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";

import Home from "./components/home/Home";
import Header from "./components/shared/Header";
import Login from "./components/login/FormLogin";
import EventDetail from "./components/evento/Evento";
import Contato from "./components/contato/Contato";
import Perfil from "./components/perfil/Perfil";
import Checagem from "./components/checagem/Checagem";
import Matchup from "./components/chaveamento/Chaveamento";
import CriarConta from "./components/login/ValidarCriacao";
import CriarContaAtleta from "./components/login/CriarConta";
import Footer from "./components/shared/Footer";
import Privacidade from "./components/politicas/Privacidade";
import Cookies from "./components/politicas/Cookies";
import TermosDeUso from "./components/politicas/TermosDeUso";
import Carregando from "./components/utils/Carregando";
import { AuthProvider } from "./services/context/AuthContext";
import WhatsApp from './assets/imgs/whats.svg';
import EsqueciSenha from "./components/login/EsqueceuSenha";


function App() {
  const main = "/";

  return (
    <AuthProvider>
      <Router>
        <div className="App">
          <Header />

          <div className="app__container">
            <section className="home__pub">
              <div className="home__pub__content">
                {" "}
                <p>Em breve novidades!</p>
              </div>
            </section>

            <div className="page-content">
              <Routes>
                <Route path={main} element={<Home />} />
                <Route path={`${main}login/`} element={<Login />} />
                <Route
                  path="/events/:id"
                  element={<EventDetail />}
                />
                <Route path={`${main}chaveamento/`} element={<Matchup />} />
                <Route path={`${main}contato/`} element={<Contato />} />
                <Route path={`${main}perfil/`} element={<Perfil />} />
                <Route path={`${main}checagem/`} element={<Checagem />} />

               
                <Route
                  path={`${main}esqueci-a-senha/`}
                  element={<EsqueciSenha />}
                ></Route>

                <Route
                  path={`${main}criar-conta/`}
                  element={<CriarConta />}
                ></Route>
                <Route
                  path={`${main}criar-conta/atleta`}
                  element={<CriarContaAtleta />}
                ></Route>
                <Route
                  path={`${main}privacidade`}
                  element={<Privacidade />}
                ></Route>
                <Route path={`${main}cookies`} element={<Cookies />}></Route>
                <Route
                  path={`${main}termos-de-uso`}
                  element={<TermosDeUso />}
                ></Route>
                <Route
                  path={`${main}carregando`}
                  element={<Carregando />}
                ></Route>
              </Routes>

              <div className="right__bar"> <div>
                <p>Em breve novidades!</p>
              </div></div>

              <div className="whats-btn">
                <a
                  href="https://api.whatsapp.com/send/?phone=5584994514529&text&type=phone_number&app_absent=0"
                  alt="whatsapp link" aria-label="WhatsApp button"
                >
                  <div className=""><img className='whatsapp-btn-svg' src={WhatsApp} alt="WhatsApp Button" /></div>
                </a>
              </div>


            </div>
          </div>

          <Footer></Footer>
        </div>
      </Router>
    </AuthProvider>
  );
}

export default App;
